import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Resp2 } from '../../models/Resp';

@Injectable({
  providedIn: 'root',
})
export class PmProductosService {
  columns: any;
  rows: any;
  message: string;

  constructor(private api: ApiService) {}

  listProducto(body = {}) {
    return new Promise((res, req) => {
      this.api.Servicio('post', 'read//beneficio_producto', body).subscribe(
        ({ status, data, message }: Resp2) => {
          this.columns = [
            { prop: 'id_beneficio' },
            { prop: 'sku' },
            { prop: 'precio_minimo' },
            { prop: 'precio_maximo' },
          ];
          this.rows = data;
          res('Exito');
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }

  readProducto(filtro = {}) {
    return new Promise((res, req) => {
      this.api.Servicio('post', 'read/beneficio_producto', filtro).subscribe(
        ({ status, data, message }: Resp2) => {
          this.columns = [
            { prop: 'id_producto' },
            { prop: 'producto' },
            { prop: 'precio' },
            { prop: 'descripcion' },
            { prop: 'id_patrocinador' },
          ];
          this.rows = data;
          res('Exito');
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }

  CU(metodo, endpoint, json = {}) {
    return new Promise((res, req) => {
      this.api
        .Servicio(metodo, `${endpoint}/beneficio_producto`, { data: json })
        .subscribe(
          ({ status, data, message }: Resp2) => {
            this.message = message;
            res('Exito');
          },
          (err) => {
            console.log(err);
          }
        );
    });
  }
}
