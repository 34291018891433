import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appRecarga]'
})
export class RecargaDirective implements OnChanges{
  @Input() appRecarga : number;

  constructor(private template:TemplateRef<any>, private container:ViewContainerRef) { 
    this.container.createEmbeddedView(template);
  }

  ngOnChanges(changes: SimpleChanges): void{
    if(changes['appRecarga']){
      this.container.clear();
      this.container.createEmbeddedView(this.template);
    }
  }

}
