import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Producto, productoInit } from '../../shared/models/Productos.model';
import { Resp } from '../../shared/models/Resp';
import { ApiService } from '../../shared/services/api.service';


@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.css'],
})
export class ProductoComponent {
  //Constructor
  constructor(private api: ApiService) { }
  ngOnInit(): void {
    this.listMarcas();
    this.MARCA = this.producto?.MARCA ?? '';
    this.COD_CAT_1 = this.producto?.COD_CAT_1 ?? '';
    this.listCategorias();
    this.COD_BARRAS = this.producto?.COD_BARRAS ?? '';
    this.SKU = this.producto?.SKU ?? '';
    this.DES_SKU = this.producto?.DES_SKU ?? '';
    this.MARCADES = this.producto?.MARCADES ?? '';
    this.listSubCategorias();
    this.COD_SUB_CAT1 = this.producto?.COD_SUB_CAT1 ?? '';
    this.listSubCategorias2();
    this.COD_SSUBCAT = this.producto?.COD_SSUBCAT ?? '';
    this.VOLUMEN = this.producto?.VOLUMEN ?? '';
    this.SABOR = this.producto?.SABOR ?? '';
    this.FABRICANTE = this.producto?.FABRICANTE ?? '';
    this.FABRICANTE2 = this.producto?.FABRICANTE2 ?? '';
    this.EMPAQUE = this.producto?.EMPAQUE ?? '';
  }

  //Variables
  @Input() producto: Producto;
  @Input() estado2: String;
  @Output() recarga = new EventEmitter<string>();
  @Output() recarga2 = new EventEmitter<string>();
  Marcas: any;
  Categorias: any;
  SubCategorias: any;
  SubCategorias2: any;
  Fabricantes: any;
  estado: boolean = false;
  estado3: boolean = false;
  COD_BARRAS: string = '';
  SKU: string = '';
  DES_SKU: string = '';
  MARCADES: string = '';
  MARCA: string = '';
  COD_CAT_1: string = '';
  COD_SUB_CAT1: string = '';
  COD_SSUBCAT: string = '';
  VOLUMEN: string = '';
  SABOR: string = '';
  FABRICANTE: string = '';
  FABRICANTE2: string = '';
  EMPAQUE: string = '';

  //Funciones
  listMarcas() {
    this.api
      .Post('Sistema/Datos/1/0/0', {})
      .subscribe(({ data, status, message }: Resp) => {
        if (data == '') {

        } else {
          this.Marcas = data;
        }
      });
  }
  listCategorias() {
    this.api
      .Post('Sistema/Datos/2/0/0', {})
      .subscribe(({ data, status, message }: Resp) => {
        this.Categorias = data;
      });
  }
  listSubCategorias() {
    if (this.COD_CAT_1 == '') return;
    this.api
      .Post('Sistema/Datos/3/' + this.COD_CAT_1 + '/0', {})
      .subscribe(({ data, status, message }: Resp) => {
        this.SubCategorias = data;
      });
  }
  listSubCategorias2() {
    if (this.COD_CAT_1 == '' || this.COD_SUB_CAT1 == '') return;
    this.api
      .Post('Sistema/Datos/4/' + this.COD_CAT_1 + '/' + this.COD_SUB_CAT1, {})
      .subscribe(({ data, status, message }: Resp) => {
        this.SubCategorias2 = data;
      });
  }
  readBarra() {
    if (this.COD_BARRAS === '') return;
    this.api
      .Post('Sistema/Datos/7/' + this.COD_BARRAS + '/0', {})
      .subscribe(({ data, status, message }: Resp) => {
        if (data != '') {
          this.COD_BARRAS = '';
          this.estado = true;
        } else {
          this.estado = false;
        }
      });
  }
  CU() {
    this.estado3 = true;
    var accion: string = '';
    if (this.estado2 == '1') {
      accion = 'C';
    } else if (this.estado2 == '2') {
      accion = 'U';
    } else if (this.estado2 == '3') {
      accion = 'CC';
    }
    this.api
      .Post('Sistema/Productos', {
        ...productoInit,
        ACCION: accion,
        BARRA: this.COD_BARRAS,
        SKU: this.SKU,
        DESCRIPCION: this.DES_SKU,
        MARCA: this.MARCA + '',
        CATEGORIA: this.COD_CAT_1 + '',
        SUBCATEGORIA: this.COD_SUB_CAT1 + '',
        SSUBCATEGORIA: this.COD_SSUBCAT + '',
        PRECIO: ''
      })
      .subscribe(({ data, status, message }: Resp) => {
        this.recarga.emit(data);
      });
  }
  createMarcas() {
    this.recarga2.emit('1');
  }
}
