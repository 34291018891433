import { Component } from '@angular/core';
import { PmTiposService } from '../../../shared/services/pushmoney/pm-tipos.service';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pm-tipos',
  templateUrl: './pm-tipos.component.html',
  styleUrls: ['./pm-tipos.component.css']
})
export class PmTiposComponent {
//Variables
ColumnMode = ColumnMode;
SelectionType = SelectionType;
columns:any;
rows:any;
tipo_beneficio:any;
metodos:any = [{label:"Unidad", value:"U"},{label:"Monto", value:"M"}];
estado:string = '0';
id:string;
nombre:string;
metodo:string;
message:string = '';
buscador: string = '';

//Constructor
constructor(private service:PmTiposService, private toast: ToastrService){
  this.listTipo();
  }
//Funciones
newTipo(){
  this.id = '';
  this.nombre = '';
  this.metodo = '';
  this.estado = '1';
}

async listTipo(){
try {
  await this.service.listTipo();
  this.columns = this.service.columns;
  this.rows = this.service.rows;
} catch (error) {
  console.log(error)
}
}

async readTipo(event){
  try {
    await this.service.readTipo(event);
    this.tipo_beneficio = this.service.tipo_beneficio;
    this.id = this.tipo_beneficio[0].id_tipo_beneficio;
    this.nombre = this.tipo_beneficio[0].tipo_beneficio;
    this.metodo = this.tipo_beneficio[0].metodo_calculo;
    this.estado = '1';
  } catch (error) {
    console.log(error)
  }
}
async CU(){
  var endpoint;
  var metodo;
  if(this.id == ''){
    endpoint = 'create';
    metodo = 'post';

  }else{
    endpoint = 'update'
    metodo = 'patch';
  }
  var status = await this.service.CU(metodo,endpoint,{
      data:[
        {
          id_tipo_beneficion:this.id,
          tipo_beneficio:this.nombre,
          metodo_calculo:this.metodo
        }
      ]
    });
    if(status=='Exito'){
      this.toast.success(this.service.message,"Exito", {closeButton : true, timeOut : 3000 ,progressAnimation : 'decreasing', progressBar : true});
      this.newTipo();
      this.listTipo();
      let close:HTMLElement= document.getElementById('Close') as HTMLElement;
      close.click();
    }else if (status=='Fallido'){
      this.toast.error(this.service.message,"Fallido", {closeButton : true, timeOut : 3000 ,progressAnimation : 'decreasing', progressBar : true});
    }
}
}