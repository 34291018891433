import { Component, Output, EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from '../../shared/services/api.service';
import { Router } from '@angular/router';
import { Resp, Resp2 } from '../../shared/models/Resp';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent {
  constructor(
    public apipost: ApiService,
    public router: Router,
    public cookie: CookieService
  ) {
    if (this.cookie.get('TOKEN_SESSION') != '') {
      this.router.navigate(['/Principal']);
    }
  }

  usuario: string;
  pass: string;
  estado: Boolean = false;

  PostLogin() {
    this.apipost
      .Get('Login/CheckUsuario', {}, { USUARIO: this.usuario, PASS: this.pass })
      .subscribe(({ data, status, message }: Resp2) => {
        if (status !== 200) {
          this.estado = true;
        } else {
          this.cookie.set('TOKEN_SESSION', data[0].TOKEN, 1);
          this.cookie.set('NOM_SESSION', data[0].NOMBRE, 1);
          this.router.navigate(['/Principal']);
          document.location.reload();
        }
      });
  }
}
