import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { Acreditacion, ListaAcreditaciones2 } from '../../shared/models/Acreditaciones.model';
import { acreditacionEmpresaInit } from '../../shared/models/AcreditacionEmpresa';
import { acreditacionTiendaInit } from '../../shared/models/AcreditacionTienda';
import { Resp } from '../../shared/models/Resp';

@Component({
  selector: 'app-acreditacion',
  templateUrl: './acreditacion.component.html',
  styleUrls: ['./acreditacion.component.css'],
})
export class AcreditacionComponent implements OnInit {
  //Constructor
  constructor(private api: ApiService) { }
  ngOnInit(): void {
    this.COD_EMPRESA = this.acreditacion[0].COD_EMPRESA;
    this.listAcreditacionesTiendas();
    this.NOM_EMPRESA = this.acreditacion[0].NOM_EMPRESA;
    this.NIT = this.acreditacion[0].NIT;
    this.GFACE_FEL_USUARIO = this.acreditacion[0].GFACE_FEL_USUARIO;
    this.DIRECCION = this.acreditacion[0].DIRECCION;
    this.GFACE_FEL_LLAVE = this.acreditacion[0].GFACE_FEL_LLAVE;
    this.GFACE_FEL_FIRMA = this.acreditacion[0].GFACE_FEL_FIRMA;
    if (
      this.acreditacion[0].TIPOFRASE == '1' &&
      this.acreditacion[0].ESCENARIO == '1'
    ) {
      this.FRASE = '1';
      this.TIPOFRASE = '1';
      this.ESCENARIO = '1';
    } else if (
      this.acreditacion[0].TIPOFRASE == '1' &&
      this.acreditacion[0].ESCENARIO == '2'
    ) {
      this.FRASE = '2';
      this.TIPOFRASE = '1';
      this.ESCENARIO = '2';
    } else if (
      this.acreditacion[0].TIPOFRASE == '3' &&
      this.acreditacion[0].ESCENARIO == '1'
    ) {
      this.FRASE = '3';
      this.TIPOFRASE = '3';
      this.ESCENARIO = '1';
    }
    if (this.acreditacion[0].SOLODOCTONOFISCAL == 'N') {
      this.SOLODOCTONOFISCAL = true;
    } else {
      this.SOLODOCTONOFISCAL = false;
    }
  }

  //Variables
  @Input() acreditacion: Acreditacion;
  @Output() recarga2 = new EventEmitter<string>();
  lista_acreditaciones2: ListaAcreditaciones2[] = [];
  Frases: any = [
    { Texto: 'Frase 1 Escenario 1', Valor: '1' },
    { Texto: 'Frase 1 Escenario 2', Valor: '2' },
    { Texto: 'Frase 3 Escenario 1', Valor: '3' }
  ];
  recarga: number = 0;
  SOLODOCTONOFISCAL: boolean = false;
  estado: boolean = false;
  COD_EMPRESA: string = '';
  NOM_EMPRESA: string = '';
  NIT: string = '';
  GFACE_FEL_USUARIO: string = '';
  DIRECCION: string = '';
  GFACE_FEL_LLAVE: string = '';
  GFACE_FEL_FIRMA: string = '';
  TIPOFRASE: string = '';
  ESCENARIO: string = '';
  FRASE: string = '';
  FEL: string = '';

  //Funciones
  listAcreditacionesTiendas() {
    this.lista_acreditaciones2 = [];
    this.api
      .Post('Empresa/Acreditaciones_Tiendas', {
        ...acreditacionTiendaInit,
        ACCION: 'L',
        EMPRESA: this.COD_EMPRESA
      })
      .subscribe(({ data, status, message }: Resp) => {
        data.forEach((dato: any) => {
          this.lista_acreditaciones2.push(dato);
          this.recarga += 1;
        });
      });
  }
  CU() {
    if (this.SOLODOCTONOFISCAL == true) {
      this.FEL = 'N';
    } else {
      this.FEL = 'S';
    }
    this.estado = true;
    this.api
      .Post('Empresa/Acreditaciones_Empresas', {
        ...acreditacionEmpresaInit,
        ACCION: 'U',
        EMPRESA: this.COD_EMPRESA,
        NOM_EMPRESA: this.NOM_EMPRESA,
        NIT: this.NIT,
        USUARIO: this.GFACE_FEL_USUARIO,
        DIRECCION: this.DIRECCION,
        LLAVE: this.GFACE_FEL_LLAVE,
        FIRMA: this.GFACE_FEL_FIRMA,
        FRASE1: this.TIPOFRASE,
        FRASE2: this.ESCENARIO,
        FEL: this.FEL,
      })
      .subscribe(({ data, status, message }: Resp) => {
        if (data == '') {
          data = '1';
        }
        this.recarga2.emit(data);
        this.estado = false;
      });
  }
  Recarga($event) {
    this.recarga2.emit($event);
    this.listAcreditacionesTiendas();
  }
}
