export interface ListaTiendas {
    EMPRESA:string;
    TIENDA:string;
    TDA_NOMBRE:string;
    NOM_ASESOR:string;
}
  
export interface Tienda{
    EMPRESA:string;
    TIENDA:string;
    TDA_NOMBRE:string;
    DIRECCION:string;
    NOMBRE_PROPIETARIO:string;
    USUARIO:string;
    PASS:string;
    TELEFONO:string;
    IDTIPOTDA:string;
    CODIGO_JDE:string;
    CUOTA:string;
    IDSEGMENTO:string;
    IDSECTOR:string;
    LATITUD:string;
    LONGITUD:string;
    IDESTADO2:string;
    IDESTADO:string;
    IDASESOR:string;
    ESTADO:string;
}
  
export const TiendaInit: Tienda = {
    EMPRESA:'',
    TIENDA:'',
    TDA_NOMBRE:'',
    DIRECCION:'',
    NOMBRE_PROPIETARIO:'',
    USUARIO:'',
    PASS:'',
    TELEFONO:'',
    IDTIPOTDA:'',
    CODIGO_JDE:'',
    CUOTA:'',
    IDSEGMENTO:'',
    IDSECTOR:'',
    LATITUD:'',
    LONGITUD:'',
    IDESTADO2:'',
    IDESTADO:'',
    IDASESOR:'',
    ESTADO:'',
}

export type TTienda = {
    ACCION: 'L' | 'R' | 'C' | 'U' | 'Z';
    EMPRESA_NUEVA: string;
    TIENDA_NUEVA: string;
    direccion: string;
    nombre_empresa: string;
    usercajero: string;
    passcajero: string;
    tipo_tienda: string;
    codigo_jde: string;
    cuota: string;
    segmento: string;
    sector: string;
    latitud: string;
    longitud: string;
    estado: string;
    asesor: string;
    estado2: string;
  };
  
  export const tiendaInit: TTienda = {
    ACCION: 'L',
    EMPRESA_NUEVA: '',
    TIENDA_NUEVA: '',
    direccion: '',
    nombre_empresa: '',
    usercajero: '',
    passcajero: '',
    tipo_tienda: '',
    codigo_jde: '',
    cuota: '',
    segmento: '',
    sector: '',
    latitud: '',
    longitud: '',
    estado: '',
    asesor: '',
    estado2: '',
  };
  