<form ngNativeValidate (submit)="CU()">
  <div class="row">
    <div class="col-2">
      <label for="Empresa" class="form-label">#Empresa</label>
      <input type="text" class="form-control" id="Empresa" name="Empresa" [(ngModel)]="EMPRESA" disabled required>
    </div>
    <div class="col-2">
      <label for="Tienda" class="form-label">#Tienda</label>
      <input type="text" class="form-control" id="Tienda" name="Tienda" [(ngModel)]="TIENDA" disabled required>
    </div>
    <div class="col-5">
      <label for="Grupo" class="form-label">Grupo</label>
      <ng-select [items]="Grupos" bindLabel="Texto" bindValue="Valor" id="Grupo" name="Grupo" [(ngModel)]="GRUPO"
        (change)="Reportes()" required></ng-select>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <label for="Usuario" class="form-label">Usuario</label>
      <input type="text" class="form-control" id="Usuario" name="Usuario" [(ngModel)]="USUARIO" (blur)="readUsuario()"
        required [disabled]="disabled">
    </div>
    <div *ngIf="estado" class="col-2 alert alert-danger text-center mt-2">
      ¡Usuario ya existente!
    </div>
    <div class="col-2">
      <label for="Contraseña" class="form-label">Contraseña</label>
      <input type="text" class="form-control" id="Contraseña" name="Contraseña" [(ngModel)]="PASS" required>
    </div>
    <div class="col-2 align-self-end">
      <div class="form-check">
        <label class="form-check-label" for="Reportes">Reportes</label>
        <input class="form-check-input" type="checkbox" id="Reportes" name="Reportes" [(ngModel)]="REPORTE"
          [disabled]="disabled2">
      </div>
    </div>
    <div class="col-2 align-self-end">
      <button type="submit" class="btn btn-dark">Guardar</button>
    </div>
    <div *ngIf="estado3" class="col-2 align-self-end">
      <strong>Cargando...</strong>
      <div class="spinner-border" role="status">
      </div>
    </div>
  </div>
</form>