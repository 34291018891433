<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-md">
    <div class="modal-content">
      <div class="modal-header">
        Patrocinador
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="Close"></button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <form ngNativeValidate (submit)="CU()">
            <div class="row">
              <div class="col-2">
                <label for="id_patrocinador" class="form-label">ID</label>
                <input type="text" class="form-control" id="id_patrocinador" name="id_patrocinador"
                  [(ngModel)]="id_patrocinador" disabled />
              </div>
              <div class="col">
                <label for="patrocinador" class="form-label">Descripcion</label>
                <input type="text" class="form-control" id="patrocinador" name="patrocinador" [(ngModel)]="patrocinador"
                  required />
              </div>
            </div>
            <br>
            <div class="row align-items-center">
              <div class="col text-center">
                <button class="btn btn-dark" type="submit">Guardar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex align-items-end" style="height: 100px;">
  <div class="col"> <input type="text" class="form-control" placeholder="Ingrese el ID o Descripcion del patrocinador"
      id="Buscador" nombre="Buscador" [(ngModel)]="buscador" (keyup.enter)="listarPatrocinador()"></div>
  <div class="col"></div>
  <div class="col"><button class="btn btn-dark" (click)="newPatrocinador()" data-bs-toggle="modal"
      data-bs-target="#staticBackdrop">Agregar Patrocinador</button></div>
</div>

<br>

<div class="col">
  <ngx-datatable class="material" [rows]="rows" rowHeight="auto" [columns]="columns" [columnMode]="ColumnMode.force"
    [limit]="10" [headerHeight]="50" [footerHeight]="50" [selectionType]="SelectionType.single"
    (select)="readPatrocinador($event)" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
  </ngx-datatable>
</div>
