<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        Beneficio
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="Close"></button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <form ngNativeValidate (submit)="CU()">
            <div class="row">
              <div class="col-2">
                <label for="ID" class="form-label">ID del beneficio</label>
                <input type="text" class="form-control" id="ID" name="ID" [(ngModel)]="id" disabled>
              </div>
              <div class="col">
                <label for="Nombre" class="form-label">Nombre del beneficio</label>
                <input type="text" class="form-control" id="Nombre" name="Nombre" [(ngModel)]="nombre" required>
              </div>
              <div class="col">
                <label for="Tipo" class="form-label">Tipo beneficio</label>
                <ng-select [items]="tipos" bindLabel="beneficio" bindValue="id_tipo_beneficio" id="Tipo" name="Tipo"
                  [(ngModel)]="tipo" required></ng-select>
              </div>
              <div class="col">
                <label for="Patrocinador" class="form-label">Patrocinador</label>
                <ng-select [items]="patrocinadores" bindLabel="patrocinador" bindValue="id_patrocinador" id="Patrocinador"
                  name="Patrocinador" [(ngModel)]="patrocinador" required></ng-select>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <label for="FechaI" class="form-label">Fecha Inicial</label>
                <input type="date" class="form-control" id="FechaI" name="FechaI" [(ngModel)]="fechai" required>
              </div>
              <div class="col">
                <label for="FechaF" class="form-label">Fecha Final</label>
                <input type="date" class="form-control" id="FechaF" name="FechaF" [(ngModel)]="fechaf" required>
              </div>
              <div class="col">
                <label for="Maximo" class="form-label">Maximo</label>
                <input type="number" class="form-control" id="Maximo" name="Maximo" [(ngModel)]="maximo" required>
              </div>
              <div class="col align-self-end">
                <button type="submit" class="btn btn-dark">Guardar</button>
              </div>
            </div>
            <br>
            <div class="row" *ngIf="this.id!=''">
              <div class="col">
                <button type="button" class="btn btn-dark" (click)="Opciones(1)">Detalle</button>
              </div>
              <div class="col">
                <button type="button" class="btn btn-dark" (click)="Opciones(2)">Productos</button>
              </div>
              <div class="col">
                <button type="button" class="btn btn-dark" (click)="Opciones(3)">Usuarios</button>
              </div>
            </div>
            <br>
            <div [ngSwitch]="opcion">
              <div class="pt-2" *ngSwitchCase="1">
                <ng-select [items]="detalles" bindLabel="descripcion_beneficio" bindValue="id_beneficio" id="Tipo" name="Tipo"
                [(ngModel)]="tipo" (change)="Select($event)" required></ng-select>
                <br>
                <div *ngIf="this.id_beneficio != ''">
                  <app-pm-detalles [id_beneficio]="this.id_beneficio" [id_rango]="this.id_rango" (message)="Recarga($event)" *appRecarga="this.recarga"></app-pm-detalles>
                </div>
              </div>
              <div *ngSwitchCase="2">
                <app-pm-productos [id_beneficio]="id" (message)="Recarga($event)"></app-pm-productos>
              </div>
              <div *ngSwitchCase="3">
                <app-pm-usuarios [id_beneficio]="id" (message)="Recarga($event)"></app-pm-usuarios>
              </div>
            </div>
          
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex align-items-end" style="height: 100px;">
  <div class="col"> <input type="text" class="form-control" placeholder="Ingrese el ID o Descripcion del beneficio"
      id="Buscador" nombre="Buscador" [(ngModel)]="buscador" (keyup.enter)="listBeneficio()"></div>
  <div class="col"></div>
  <div class="col"><button class="btn btn-dark" (click)="newBeneficio()" data-bs-toggle="modal"
      data-bs-target="#staticBackdrop">Agregar Beneficio</button></div>
</div>

<br>

<div class="col">
  <ngx-datatable class="material" [rows]="rows" rowHeight="auto" [columns]="columns" [columnMode]="ColumnMode.force"
    [limit]="10" [headerHeight]="50" [footerHeight]="50" [selectionType]="SelectionType.single"
    (select)="readBeneficio($event)" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
  </ngx-datatable>
</div>