<form ngNativeValidate (submit)="CU()">
  <div class="row">
    <div class="col-6">
      <label for="Razon" class="form-label">Razon Social</label>
      <input type="text" class="form-control" id="Razon" name="Razon" [(ngModel)]="NOM_EMPRESA" required>
    </div>
    <div class="col-3">
      <label for="NIT" class="form-label">NIT</label>
      <input type="text" class="form-control" id="NIT" name="NIT" [(ngModel)]="NIT" required>
    </div>
    <div class="col-3">
      <label for="Direccion" class="form-label">Direccion</label>
      <input type="text" class="form-control" id="Direccion" name="Direccion" [(ngModel)]="DIRECCION" required>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <label for="Usuario" class="form-label">Usuario FEL</label>
      <input type="text" class="form-control" id="Usuario" name="Usuario" [(ngModel)]="GFACE_FEL_USUARIO" required>
    </div>
    <div class="col-4">
      <label for="Llave" class="form-label">Llave FEL</label>
      <input type="text" class="form-control" id="Llave" name="Llave" [(ngModel)]="GFACE_FEL_LLAVE" required>
    </div>
    <div class="col-4">
      <label for="Firma" class="form-label">Firma FEL</label>
      <input type="text" class="form-control" id="Firma" name="Firma" [(ngModel)]="GFACE_FEL_FIRMA" required>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <label for="Frases" class="form-label">Frases</label>
      <ng-select [items]="Frases" bindLabel="Texto" bindValue="Valor" id="Frases" name="Frases" [(ngModel)]="FRASE"
        required></ng-select>
    </div>
    <div class="col-2 align-self-end">
      <div class="form-check">
        <label class="form-check-label" for="FEL">¿Es FEL?</label>
        <input class="form-check-input" type="checkbox" id="FEL" name="FEL" [(ngModel)]="SOLODOCTONOFISCAL">
      </div>
    </div>
  </div>
  <div class="row">
    <label for="Tiendas" class="form-label">Tiendas</label>
    <div class="accordion accordion-flush" id="Tiendas">
      <div class="accordion-item" *ngFor="let acreditacion of this.lista_acreditaciones2; let i = index;">
        <h2 class="accordion-header" [attr.id]="'head' + i">
          <button class="accordion-button" type="button" data-bs-toggle="collapse"
            [attr.data-bs-target]="'#collapse' + i" aria-expanded="true" [attr.aria-controls]="'collapse' + i">
            {{acreditacion.NOMBRE}}
          </button>
        </h2>
        <div [attr.id]="'collapse' + i" class="accordion-collapse collapse" [attr.aria-labelledby]="'head' + i"
          data-bs-parent="#Tiendas">
          <div class="accordion-body">
            <app-acreditacion2 *appRecarga="recarga" [buscador]="acreditacion.EMPRESA" [buscador2]="acreditacion.TIENDA"
              (recarga)="Recarga($event)"></app-acreditacion2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-1 align-self-end">
      <button type="submit" class="btn btn-dark">Guardar</button>
    </div>
    <div *ngIf="estado" class="col-2 align-self-end">
      <strong>Cargando...</strong>
      <div class="spinner-border" role="status">
      </div>
    </div>
  </div>
</form>