<form ngNativeValidate (submit)="CU()">
  <div class="row">
    <div class="col-2">
      <label for="Empresa" class="form-label">#Empresa</label>
      <input type="text" class="form-control" id="Empresa" name="Empresa" [(ngModel)]="EMPRESA" disabled required>
    </div>
    <div class="col-2">
      <label for="Tienda" class="form-label">#Tienda</label>
      <input type="text" class="form-control" id="Tienda" name="Tienda" [(ngModel)]="TIENDA" disabled required>
    </div>
    <div class="col-8">
      <label for="Nombre" class="form-label">Nombre de la empresa</label>
      <input type="text" class="form-control" id="Nombre" name="Nombre" [(ngModel)]="TDA_NOMBRE" required>
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <label for="Direccion" class="form-label">Direccion de la tienda</label>
      <input type="text" class="form-control" id="Direccion" name="Direccion" [(ngModel)]="DIRECCION" required>
    </div>
    <div class="col-4">
      <label for="Propietario" class="form-label">Propietario de la tienda</label>
      <input type="text" class="form-control" id="Propietario" name="Propietario" [(ngModel)]="NOMBRE_PROPIETARIO"
        required [disabled]="this.estado2!='1'">
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <label for="Usuario" class="form-label">Usuario</label>
      <input type="text" class="form-control" id="Usuario" name="Usuario" [(ngModel)]="USUARIO" disabled required>
    </div>
    <div class="col-2">
      <label for="Contraseña" class="form-label">Pass</label>
      <input type="text" class="form-control" id="Contraseña" name="Contraseña" [(ngModel)]="PASS" disabled required>
    </div>
    <div class="col-2">
      <label for="Telefono" class="form-label">Telefono</label>
      <input type="text" class="form-control" id="Telefono" name="Telefono" [(ngModel)]="TELEFONO" required
        [disabled]="this.estado2!='1'">
    </div>
    <div class="col-2">
      <label for="JDE" class="form-label">JDE</label>
      <input type="text" class="form-control" id="JDE" name="JDE" [(ngModel)]="CODIGO_JDE" required>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <label for="Cuota" class="form-label">Cuota</label>
      <div class="input-group">
        <span class="input-group-text" id="basic-addon1">Q.</span>
        <input type="text" class="form-control" id="Cuota" name="Cuota" aria-describedby="basic-addon1"
          style="border-left: 1px solid black;" [(ngModel)]="CUOTA" required>
      </div>
    </div>
    <div class="col-3">
      <label for="TipoTienda" class="form-label">Tipo de tienda</label>
      <ng-select [items]="TipoTiendas" bindLabel="IDTIPOTDA_DESC" bindValue="IDTIPOTDA" id="TipoTienda"
        name="TipoTienda" [(ngModel)]="IDTIPOTDA" required></ng-select>
    </div>
    <div class="col-3">
      <label for="Segmento" class="form-label">Segmento</label>
      <ng-select [items]="Segmentos" bindLabel="segmento_Desc" bindValue="idSegmento" id="Segmento" name="Segmento"
        [(ngModel)]="IDSEGMENTO" required></ng-select>
    </div>
    <div class="col-3">
      <label for="Sector" class="form-label">Sector</label>
      <ng-select [items]="Sectores" bindLabel="sector_Desc" bindValue="idSector" id="Sector" name="Sector"
        [(ngModel)]="IDSECTOR" required></ng-select>
    </div>
  </div>
  <div class="row">
    <div class="col-2">
      <label for="Latitud" class="form-label">Latitud</label>
      <input type="text" class="form-control" id="Latitud" name="Latitud" [(ngModel)]="LATITUD" required>
    </div>
    <div class="col-2">
      <label for="Longitud" class="form-label">Longitud</label>
      <input type="text" class="form-control" id="Longitud" name="Longitud" [(ngModel)]="LONGITUD" required>
    </div>
    <div class="col-3">
      <label for="Departamento" class="form-label">Departamento</label>
      <ng-select [items]="Departamentos" bindLabel="ESTADO" bindValue="IDESTADO" id="Departamento" name="Departamento"
        [(ngModel)]="IDESTADO2" required (change)="listMunicipios()">
      </ng-select>
    </div>
    <div class="col-3">
      <label for="Municipio" class="form-label">Municipio</label>
      <ng-select [items]="Municipios" bindLabel="ESTADO" bindValue="IDESTADO" id="Municipio" name="Municipio"
        [(ngModel)]="IDESTADO" required>
      </ng-select>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <label for="Asesor" class="form-label">Asesor</label>
      <ng-select [items]="Asesores" bindLabel="NOM_ASESOR" bindValue="IDASESOR" id="Asesor" name="Asesor"
        [(ngModel)]="IDASESOR" required>
      </ng-select>
    </div>
    <div class="col-2">
      <label for="Estado" class="form-label">Estado</label>
      <ng-select [items]="Estados" bindLabel="Texto" bindValue="Valor" id="Estado" name="Estado" [(ngModel)]="ESTADO"
        required>
      </ng-select>
    </div>
    <div class="col-1 align-self-end">
      <button type="submit" class="btn btn-dark">Guardar</button>
    </div>
    <div *ngIf="estado3" class="col-2 align-self-end">
      <strong>Cargando...</strong>
      <div class="spinner-border" role="status">
      </div>
    </div>
  </div>