import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Usuario, usuariosInit } from '../../shared/models/Usuarios.model';
import { Resp } from '../../shared/models/Resp';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css'],
})
export class UsuarioComponent {
  //Constructor
  constructor(private api: ApiService, private cookie: CookieService, private toast: ToastrService) { }
  ngOnInit(): void {
    this.EMPRESA = this.usuario.EMPRESA;
    this.TIENDA = this.usuario.TIENDA;
    this.USUARIO = this.usuario.USUARIO;
    if (this.USUARIO == '') {
      this.disabled = false;
    }
    this.PASS = this.usuario.PASS;
    this.GRUPO = this.usuario.GRUPO;
    if (this.usuario.REPORTES == null) {
      this.REPORTE = false;
    } else {
      this.REPORTE = true;
    }
  }

  //Variables
  @Input() usuario: Usuario;
  @Input() estado2: String;
  @Output() recarga = new EventEmitter<string>();
  Grupos: any = [
    { Texto: 'Administradores', Valor: '00001' },
    { Texto: 'Propietarios de tienda', Valor: '00003' },
    { Texto: 'Dependientes de tienda', Valor: '00004' },
  ];
  REPORTE: boolean = false;
  estado: boolean = false;
  estado3: boolean = false;
  disabled: boolean = true;
  disabled2: boolean = false;
  EMPRESA: string = '';
  TIENDA: string = '';
  CAJA: string = '';
  USUARIO: string = '';
  PASS: string = '';
  GRUPO: string = '';


  //Funciones
  readUsuario() {
    this.api
      .Post('Empresa/Usuarios', {
        ...usuariosInit,
        ACCION: 'R',
        USUARIO: this.USUARIO
      })
      .subscribe(({ data, status, message }: Resp) => {
        if (data[0] != null) {
          this.USUARIO = '';
          this.toast.warning("Usuario ya existente", "", { closeButton: true, timeOut: 3000, progressAnimation: 'decreasing', progressBar: true });
        }
      });
  }
  CU() {
    this.estado3 = true;
    var accion: string = '';
    if (this.estado2 == '1') {
      accion = 'C'
    } else if (this.estado2 == '2') {
      accion = 'U'
    }
    var reporte = '';
    if (this.REPORTE == true) {
      reporte = 'SI';
    } else {
      reporte = 'NO';
    }
    this.api
      .Post('Empresa/Usuarios', {
        ...usuariosInit,
        ACCION: accion,
        EMPRESA: this.EMPRESA,
        TIENDA: this.TIENDA,
        GRUPO: this.GRUPO,
        USUARIO: this.USUARIO,
        PASS: this.PASS,
        USUARIO2: this.USUARIO,
      })
      .subscribe(({ data, status, message }: Resp) => {
        this.recarga.emit(data);
      });
  }
  Reportes() {
    if (this.GRUPO == '00004') {
      this.REPORTE = false;
      this.disabled2 = true;
    } else {
      this.disabled2 = false;
    }
  }
}
