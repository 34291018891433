import { Injectable } from "@angular/core";
import { Resp2 } from "../../models/Resp";
import { ApiService } from "../api.service";
import { Tipo_Beneficio } from "../../models/Pm-Tipos.model";

@Injectable({
    providedIn: 'root',
  })
export class PmTiposService{
//Variables
columns: any;
rows: any;
tipo_beneficio:Tipo_Beneficio;
message:string;

//Constructor
constructor(private api: ApiService){

}

//Funciones
listTipo(){
    return new Promise((res,rej) => {    
        this.api
        .Servicio('post','read/tipo_beneficio', {})
        .subscribe(
          ({ status, data, message }: Resp2) => {
            this.columns = [
                { prop: 'id_tipo_beneficio',name: "ID" },
                { prop: 'tipo_beneficio', name: "DESCRIPCION" },
            ];
            this.rows = data;
            res("Exito")
          },
          (err) => {
           console.log(err);
          });
        });
}
readTipo(event){
    return new Promise((res,rej) => {    
        this.api
        .Servicio('post','read/tipo_beneficio', {id_tipo_beneficion:event.selected[0].id_tipo_beneficio})
        .subscribe(
          ({ status, data, message }: Resp2) => {
            this.tipo_beneficio = data;
            res("Exito")
          },
          (err) => {
           console.log(err);
          });
        });
}
CU(metodo,endpoint,json){
    return new Promise((res,rej) => {    
        this.api
        .Servicio(metodo,endpoint + '/tipo_beneficio', json)
        .subscribe(
          ({ status, data, message }: Resp2) => {
            this.message = message;
            res("Exito")
          },
          (err) => {
            console.log(err);
            res("Fallido");
          });
        });
}
}