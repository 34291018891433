import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PmDetalleService } from '../../../shared/services/pushmoney/pm-detalles.service';
import { end } from '@popperjs/core';

@Component({
  selector: 'app-pm-detalles',
  templateUrl: './pm-detalles.component.html',
  styleUrls: ['./pm-detalles.component.css']
})
export class PmDetallesComponent {
//Constructor
constructor(private service:PmDetalleService){}
ngOnInit(): void {
  this.readDetalle()
}

//Variables
@Input() id_beneficio: string;
@Input() id_rango: string;
@Output() message = new EventEmitter<string>();
detalle:any;
meta:string;
valor:string;
descripcion:string;

//Funciones
async readDetalle(){
  try {
    await this.service.readDetalle(this.id_beneficio,this.id_rango);
    this.detalle = this.service.detalle;
    this.meta = this.detalle[0].valor_meta_rango;
    this.valor = this.detalle[0].valor_beneficio;
    this.descripcion = this.detalle[0].descripcion_beneficio;
  } catch (error) {
    console.log(error)
  }
}
async updateDetalle(){
  try {
    await this.service.CU('patch','update',{
      data:[
        {
          id_beneficio:this.id_beneficio,
          id_rango:this.id_rango,
          valor_meta_rango:this.meta,
          valor_beneficio :this.valor,
          descripcion_beneficio:this.descripcion,
        }
      ]
    });
    this.message.emit(this.service.message);
  } catch (error) {
    console.log(error)
  }
}
}