import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Equipo } from '../../shared/models/Equipos.model';
import { equiposInit } from '../../shared/models/Equipos.model';
import { Resp } from '../../shared/models/Resp';
import { ToastrService } from 'ngx-toastr';
import * as Swal from 'sweetalert2';

@Component({
  selector: 'app-equipo',
  templateUrl: './equipo.component.html',
  styleUrls: ['./equipo.component.css'],
})
export class EquipoComponent {
  //Constructor
  constructor(private api: ApiService, private cookie: CookieService, private toast: ToastrService) { }
  ngOnInit(): void {
    this.listUbicaciones();
    this.EMPRESA = this.equipo.EMPRESA;
    this.TIENDA = this.equipo.TIENDA;
    this.CAJA = this.equipo.CAJA;
    this.SERIAL1 = this.equipo.SERIAL1;
    this.SERIAL2 = this.equipo.SERIAL2;
    this.SERIAL3 = this.equipo.SERIAL3;
    this.SERIAL4 = this.equipo.SERIAL4;
    this.SERIAL5 = this.equipo.SERIAL5;
    this.UBICACION = this.equipo.UBICACION;
    this.UBICACION2 = this.equipo.UBICACION2;
    this.INFORMADOR = this.equipo.INFORMADOR;
    this.COMENTARIO = this.equipo.COMENTARIO;
    this.ESTADO = this.equipo.ESTADO;
  }

  //Variables
  @Input() equipo: Equipo;
  @Input() estado2: String;
  @Output() recarga = new EventEmitter<string>();
  Ubicaciones: any = [];
  ESTADO: boolean = true;
  estado: boolean = false;
  estado3: boolean = false;
  EMPRESA: string = '';
  TIENDA: string = '';
  CAJA: string = '';
  UBICACION: string = '';
  UBICACION2: string = '';
  INFORMADOR: string = '';
  COMENTARIO: string = '';
  SERIAL1: string = '';
  SERIAL2: string = '';
  SERIAL3: string = '';
  SERIAL4: string = '';
  SERIAL5: string = '';

  //Funciones
  listUbicaciones() {
    this.api
      .Post('Sistema/Datos/6/0/0', {})
      .subscribe(({ data, status, message }: Resp) => {
        this.Ubicaciones = data;
      });
  }
  CU() {
    this.estado3 = true;
    var accion: string = '';
    if (this.estado2 == '1') {
      accion = 'C'
    } else if (this.estado2 == '2') {
      accion = 'U'
    }
    this.api
      .Post('Sistema/Equipos', {
        ...equiposInit,
        ACCION: accion,
        EMPRESA: this.EMPRESA,
        TIENDA: this.TIENDA,
        CAJA: this.CAJA,
        UBICACION: this.UBICACION + '',
        UBICACION2: this.UBICACION2 + '',
        INFORMADOR: this.INFORMADOR,
        COMENTARIO: this.COMENTARIO,
        SERIAL1: this.SERIAL1 + '',
        SERIAL2: this.SERIAL2 + '',
        SERIAL3: this.SERIAL3 + '',
        SERIAL4: this.SERIAL4 + '',
        SERIAL5: this.SERIAL5 + ''
      })
      .subscribe(({ data, status, message }: Resp) => {
        this.recarga.emit(data);
      });
  }
  checkUbicacion() {
    if (this.UBICACION == this.UBICACION2) {
      Swal.default.fire({
        icon: 'warning',
        text: 'La ubicacion de origen y la ubicacion de destino no pueden ser las mismas'
      });
      this.UBICACION = '';
      this.UBICACION2 = '';
    }
  }
}
