import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { CookieService } from 'ngx-cookie-service';
import { ToastrModule } from 'ngx-toastr';
import { ApiService } from './shared/services/api.service';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { RecargaDirective } from './shared/directives/recarga.directive';
import { AcreditacionComponent } from './components/acreditacion/acreditacion.component';
import { Acreditacion2Component } from './components/acreditacion2/acreditacion2.component';
import { ProductoComponent } from './components/producto/producto.component';
import { TiendaComponent } from './components/tienda/tienda.component';
import { UsuarioComponent } from './components/usuario/usuario.component';
import { AcreditacionesComponent } from './pages/acreditaciones/acreditaciones.component';
import { ErrorComponent } from './pages/error/error.component';
import { InventariosComponent } from './pages/inventarios/inventarios.component';
import { LoginComponent } from './pages/login/login.component';
import { PlantillasComponent } from './pages/plantillas/plantillas.component';
import { PmBeneficiosComponent } from './pages/PushMoney/pm-beneficios/pm-beneficios.component';
import { PmDetallesComponent } from './pages/PushMoney/pm-detalles/pm-detalles.component';
import { PmPatrocinadoresComponent } from './pages/PushMoney/pm-patrocinadores/pm-patrocinadores.component';
import { PmProductosComponent } from './pages/PushMoney/pm-productos/pm-productos.component';
import { PmTiposComponent } from './pages/PushMoney/pm-tipos/pm-tipos.component';
import { PmUsuariosComponent } from './pages/PushMoney/pm-usuarios/pm-usuarios.component';
import { PreciosComponent } from './pages/precios/precios.component';
import { PrincipalComponent } from './pages/principal/principal.component';
import { ProductosComponent } from './pages/productos/productos.component';
import { TiendasComponent } from './pages/tiendas/tiendas.component';
import { UsuariosComponent } from './pages/usuarios/usuarios.component';
import { EquiposComponent } from './pages/equipos/equipos.component';
import { EquipoComponent } from './components/equipo/equipo.component';
import { ReportesComponent } from './pages/reportes/reportes.component';

const appRoutes: Routes = [
  { path: 'Login', component: LoginComponent },
  { path: 'Principal', component: PrincipalComponent },
  { path: 'Tiendas', component: TiendasComponent },
  { path: 'Usuarios', component: UsuariosComponent },
  { path: 'Precios', component: PreciosComponent },
  { path: 'Inventarios', component: InventariosComponent },
  { path: 'Acreditaciones', component: AcreditacionesComponent },
  { path: 'Productos', component: ProductosComponent },
  { path: 'Equipos', component: EquiposComponent },
  { path: 'Plantillas', component: PlantillasComponent },
  { path: 'Ventas', component: ReportesComponent },
  { path: 'Cambios Admin', component: ReportesComponent },
  { path: 'Cambios Equipos', component: ReportesComponent },
  { path: 'Asignar beneficios a usuarios', component: PmUsuariosComponent },
  { path: 'Asignar productos a beneficios', component: PmProductosComponent },
  { path: 'Beneficios', component: PmBeneficiosComponent },
  { path: 'Patrocinadores', component: PmPatrocinadoresComponent },
  { path: 'Tipos de beneficios', component: PmTiposComponent },
  { path: '**', component: ErrorComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    TiendasComponent,
    TiendaComponent,
    UsuariosComponent,
    UsuarioComponent,
    PreciosComponent,
    InventariosComponent,
    AcreditacionesComponent,
    AcreditacionComponent,
    ProductosComponent,
    ProductoComponent,
    LoginComponent,
    PrincipalComponent,
    RecargaDirective,
    Acreditacion2Component,
    ErrorComponent,
    PlantillasComponent,
    PmUsuariosComponent,
    PmProductosComponent,
    PmBeneficiosComponent,
    PmPatrocinadoresComponent,
    PmTiposComponent,
    PmDetallesComponent,
    EquiposComponent,
    EquipoComponent,
    ReportesComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    NgxDatatableModule,
    NgSelectModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
  ],
  providers: [CookieService, ApiService],
  bootstrap: [AppComponent],
})
export class AppModule { }
