<br>
<form ngNativeValidate (submit)="CU()">
  <div class="row">
    <div class="col-8">
      <!-- SELECT PRODUCTOS SIN ASIGNAR -->
      <label for="unassigned">Productos no asignados</label>
      <ng-select [items]="products" placeholder="Seleccione un producto" bindLabel="DES_SKU" bindValue="SKU"
        [(ngModel)]="selectedProduct" name="selectedProduct" [searchable]="true" (search)="listarProductos($event)" required>
      </ng-select>
    </div>
    <div class="row">
      <div class="col-2"><label for="precio_minimo" class="form-label">Precio mínimo</label><input type="number"
          class="form-control" id="precio_minimo" name="precio_minimo" [(ngModel)]="precio_minimo" required></div>
      <div class="col-2"><label for="precio_maximo" class="form-label">Precio máximo</label><input type="number"
          class="form-control" id="precio_maximo" name="precio_maximo" [(ngModel)]="precio_maximo" required></div>
      <div class="col-1 align-self-end">
        <button class="btn btn-dark" type="submit">Guardar</button>
      </div>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-8">
      <!-- SELECT PRODUCTOS ASIGNADOS -->
      <label for="assigned">Productos asignados</label>
      <ng-select id="asignados" [items]="rows" placeholder="Seleccione un producto" [(ngModel)]="rows"
        name="removeProducts" bindLabel="des_sku" bindValue="sku" (change)="onChange($event)">
      </ng-select>
    </div>
  </div>
</form>
