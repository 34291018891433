import { Injectable } from '@angular/core';
import { Resp2 } from '../../models/Resp';
import { ApiService } from '../api.service';
import { Beneficio } from '../../models/Pm-Beneficios.model';

@Injectable({
  providedIn: 'root',
})
export class PmBeneficiosService {
  //Constructor
  constructor(private api: ApiService) {}

  //Variables
  columns: any;
  rows: any;
  beneficio: Beneficio;
  message: string;

  //Funciones
  listBeneficio() {
    return new Promise((res, rej) => {
      this.api.Servicio('post', 'read/beneficio', {}).subscribe(
        ({ status, data, message }: Resp2) => {
          this.columns = [
            { prop: 'id_beneficio', name: 'ID' },
            { prop: 'beneficio', name: 'DESCRIPCION' },
            { prop: 'fecha_inicial', name: 'FECHA DE INICIO' },
            { prop: 'fecha_final', name: 'FECHA DE FINALIZACION' },
          ];
          this.rows = data;
          res('Exito');
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }
  readBeneficio(event) {
    return new Promise((res, rej) => {
      this.api
        .Servicio('post', 'read/beneficio', {
          id_beneficio: event.selected[0].id_beneficio,
        })
        .subscribe(
          ({ status, data, message }: Resp2) => {
            this.beneficio = data;
            res('Exito');
          },
          (err) => {
            console.log(err);
          }
        );
    });
  }
  lastBeneficio() {
    return new Promise((res, rej) => {
      this.api
        .Servicio('post', 'read/beneficio', {
          tipo_leer: 'TOP',
        })
        .subscribe(
          ({ status, data, message }: Resp2) => {
            this.beneficio = data;
            res(data);
          },
          (err) => {
            console.log(err);
          }
        );
    });
  }
  CU(metodo, endpoint, json) {
    return new Promise((res, rej) => {
      this.api.Servicio(metodo, endpoint + '/beneficio', json).subscribe(
        ({ status, data, message }: Resp2) => {
          this.message = message;
          res('Exito');
        },
        (err) => {
          console.log(err);
          res('Fallido');
        }
      );
    });
  }
}
