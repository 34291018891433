import { Component } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ApiService } from '../../shared/services/api.service';
import { ListaPrecios, listaPreciosInit } from '../../shared/models/Precios.model';
import { ToastrService } from 'ngx-toastr';
import * as Swal from 'sweetalert2';

@Component({
  selector: 'app-precios',
  templateUrl: './precios.component.html',
  styleUrls: ['./precios.component.css'],
})
export class PreciosComponent {
  //Constructor
  constructor(
    private api: ApiService,
    private toast: ToastrService
  ) {
    this.listPrecios();
  }

  //Variables
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  lista_precios: ListaPrecios[] = [];
  rows: any;
  columns: any;
  estado3: boolean = false;
  modo: string = '0';
  estado: string = '';
  EMPRESA: string = '';
  TIENDA: string = '';
  LISTA: string = 'P';
  estado2: string = '';
  buscador: string = '';

  //Funciones
  listPrecios() {
    var buscador2: string;
    if (this.buscador == '') {
      buscador2 = '0';
    } else {
      buscador2 = this.buscador;
    }
    this.api
      .Post('Empresa/Precios', {
        ...listaPreciosInit,
        EMPRESA: buscador2,
        TIENDA: buscador2,
      })
      .subscribe((data) => {
        this.lista_precios = [];
        data.data.forEach((dato: any) => {
          this.lista_precios.push(dato);
        });
        this.rows = this.lista_precios;
        this.columns = [
          { prop: 'EMPRESA', name: '#Empresa' },
          { prop: 'TIENDA', name: '#Tienda' },
          { prop: 'LISTA_TIENDA', name: 'Lista de precios' },
          { prop: 'TDA_NOMBRE', name: 'Nombre de tienda' },
        ];
        this.modo = '0';
      });
  }
  readPrecios(buscador2) {
    var b = 0;
    if (this.modo == '0') {
      this.EMPRESA = buscador2.selected[0].EMPRESA;
      this.TIENDA = buscador2.selected[0].TIENDA;
      this.api
        .Post('Empresa/Precios', {
          ...listaPreciosInit,
          ACCION: 'R',
          EMPRESA: this.EMPRESA,
          TIENDA: this.TIENDA,
        })
        .subscribe((data) => {
          this.lista_precios = [];
          data.data.forEach((dato: any) => {
            this.lista_precios.push(dato);
            if (dato.LISTA.substring(0, 1) == 'A') {
              var a = Number(dato.LISTA.substring(1, 2));
              a += 1;
              this.LISTA = 'A' + a;
              b = 1;
            }
            if (dato.LISTA.substring(0, 1) == 'P' && b == 0) {
              var a = Number(dato.LISTA.substring(1, 2));
              a += 1;
              this.LISTA = 'P' + a;
            }
          });
          this.rows = this.lista_precios;
          this.rows.push({ EMPRESA: 'Agregar Lista' });
          this.columns = [{ prop: 'EMPRESA' }, { prop: 'LISTA' }];
          this.modo = '1';
        });
    } else {
      if (buscador2.selected[0].EMPRESA == 'Agregar Lista') {
        this.estado = '1';
        Swal.default
          .fire({
            icon: 'warning',
            title: '¿Deseas crear la lista de precios?',
            cancelButtonText: `No`,
            confirmButtonText: `Si`,
            showCancelButton: true,
          })
          .then((data) => {
            if (data.isConfirmed) {
              this.CU();
            }
          });
      } else {
        this.estado = '2';
        this.LISTA = buscador2.selected[0].LISTA;
        this.CU();
      }
    }
  }
  CU() {
    this.estado3 = true;
    var accion: string = '';
    if (this.estado == '1') {
      accion = 'C';
    } else if (this.estado == '2') {
      accion = 'U';
    }
    this.api
      .Post('Empresa/Precios', {
        ...listaPreciosInit,
        ACCION: accion,
        EMPRESA: this.EMPRESA,
        TIENDA: this.TIENDA,
        LISTA: this.LISTA,
      })
      .subscribe((data) => {
        this.estado3 = false;
        this.modo = '0';
        this.listPrecios();
        if (data.data[0] == null) {
          this.toast.success('Ingresado correctamente', 'Exíto', {
            closeButton: true,
            timeOut: 3000,
            progressAnimation: 'decreasing',
            progressBar: true,
          });
        } else {
          this.toast.error(data.data[0].ERROR, 'Fallido', {
            closeButton: true,
            timeOut: 3000,
            progressAnimation: 'decreasing',
            progressBar: true,
          });
        }
      });
  }
}
