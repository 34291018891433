import { Component } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ApiService } from '../../shared/services/api.service';
import { Acreditacion, ListaAcreditaciones } from '../../shared/models/Acreditaciones.model';
import { acreditacionEmpresaInit } from '../../shared/models/AcreditacionEmpresa';
import { Resp } from '../../shared/models/Resp';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-acreditaciones',
  templateUrl: './acreditaciones.component.html',
  styleUrls: ['./acreditaciones.component.css'],
})
export class AcreditacionesComponent {
  //Constructor
  constructor(private api: ApiService, private toast: ToastrService) {
    this.listAcreditaciones();
  }

  //Variables
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  lista_acreditaciones: ListaAcreditaciones[] = [];
  acreditacion: Acreditacion;
  rows: any;
  columns: any;
  estado: boolean = false;
  recarga: number = 0;
  tienda2: number;
  estado2: string = '';
  buscador: string = '';

  //Funciones
  listAcreditaciones() {
    var buscador2: string;
    if (this.buscador == '') {
      buscador2 = '0';
    } else {
      buscador2 = this.buscador;
    }
    this.api
      .Post('Empresa/Acreditaciones_Empresas', {
        ...acreditacionEmpresaInit,
        EMPRESA: buscador2,
        NOM_EMPRESA: buscador2
      })
      .subscribe((data) => {
        this.lista_acreditaciones = [];
        data.data.forEach((dato: any) => {
          this.lista_acreditaciones.push(dato);
        });
        this.rows = this.lista_acreditaciones;
        this.columns = [
          { prop: 'COD_EMPRESA', name: '#Empresa' },
          { prop: 'NOM_EMPRESA', name: 'Nombre de empresa' },
          { prop: 'NIT', name: 'Nit' },
          { prop: 'SOLODOCTONOFISCAL', name: '¿Es ticket?' },
        ];
      });
  }
  readAcreditacion(buscador2) {
    this.api
      .Post('Empresa/Acreditaciones_Empresas', {
        ...acreditacionEmpresaInit,
        ACCION: 'R',
        EMPRESA: buscador2.selected[0].COD_EMPRESA,
      })
      .subscribe(({ data, status, message }: Resp) => {
        this.acreditacion = data;
        this.recarga += 1;
        this.Invisible();
      });
  }
  Recarga($event) {
    if ($event == 1) {
      this.toast.success("Ingresado correctamente", "Exíto", { closeButton: true, timeOut: 3000, progressAnimation: 'decreasing', progressBar: true });
    } else {
      this.toast.error($event.data[0].ERROR, "Fallido", { closeButton: true, timeOut: 3000, progressAnimation: 'decreasing', progressBar: true });
    }
    this.listAcreditaciones();
  }
  Invisible() {
    let invisible: HTMLElement = document.getElementById('invisible') as HTMLElement;
    invisible.click();
  }
}
