import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { PmProductosService } from '../../../shared/services/pushmoney/pm-productos.service';
import { ApiService } from '../../../shared/services/api.service';
import { Resp2 } from '../../../shared/models/Resp';
import { productoInit } from '../../../shared/models/Productos.model';

@Component({
  selector: 'app-pm-productos',
  templateUrl: './pm-productos.component.html',
  styleUrls: ['./pm-productos.component.css'],
})
export class PmProductosComponent {
  @Input() id_beneficio: string;
  @Output() message = new EventEmitter<string>();
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  buscador: string = '';
  columns: any;
  rows: any;
  products: any;
  estado: string = '';
  estado2: string = '';
  estado3: string = '';
  removeProducts: string = '';
  selectedProduct: any;
  precio_minimo: number = 0;
  precio_maximo: number = 0;

  constructor(private service: PmProductosService, private api: ApiService) {}
  ngOnInit(): void {
    this.listarProductosAsignados();
    this.listarProductos();
  }

  listarProductos(event?) {
    this.api
      .Post('Sistema/Productos', {
        ...productoInit,
        BARRA: event?.term ?? '',
      })
      .subscribe(({ data, status, message }: Resp2) => {
        this.products = data;
        this.products.forEach((element) => {
          element.DES_SKU = element.SKU + ' ' + element.DES_SKU;
        });
      });
  }

  newProducto() {
    this.id_beneficio = '';
    this.estado = '1';
  }

  async listarProductosAsignados() {
    try {
      await this.service.listProducto({
        id_beneficio: this.id_beneficio,
      });
      this.columns = this.service.columns;
      this.rows = this.service.rows;
    } catch (error) {
      console.log(error);
    }
  }

  async buscarProducto() {
    try {
      await this.service.readProducto(
        this.buscador != ''
          ? {
              id_beneficio: this.buscador,
            }
          : undefined
      );
      this.columns = this.service.columns;
      this.rows = this.service.rows;
    } catch (error) {
      console.log(error);
    }
  }

  async readProducto(event) {
    this.id_beneficio = event.selected[0].id_beneficio;
    this.estado = '1';
  }

  onChange(event) {
    // DESASIGNAR PRODUCTO
    let data = {
      id_beneficio: this.id_beneficio,
      sku: event.selected[0].sku,
    };
  }

  async CU(metodo: string = 'post', endpoint: string = 'create') {
    try {
      await this.service.CU(metodo, endpoint, [
        {
          id_beneficio: this.id_beneficio,
          sku: this.selectedProduct,
          precio_minimo: this.precio_minimo,
          precio_maximo: this.precio_maximo,
        },
      ]);
      this.message.emit(this.service.message);
      this.precio_minimo = 0;
      this.precio_maximo = 0;
      this.selectedProduct = '';
      this.listarProductosAsignados();
    } catch (error) {
      console.log(error);
    }
  }
}
