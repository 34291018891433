<br>
<form ngNativeValidate (submit)="CU()">
  <div class="row">
    <div class="col-8">
      <!-- SELECT TIENDAS -->
      <label for="">Tiendas</label>
      <ng-select [items]="tiendas" placeholder="Seleccione un usuario" [(ngModel)]="selectedTienda"
        name="selectedTiendas" bindLabel="TDA_NOMBRE" (change)="onChangeTienda($event)" required>
      </ng-select>
    </div>
  </div>
  <div class="row">
    <div class="col-8">
      <!-- SELECT USUARIOS SIN ASIGNAR -->
      <label for="">Usuarios sin asignar</label>
      <ng-select [items]="usuarios" placeholder="Seleccione un usuario" bindLabel="USUARIO" bindValue="USUARIO"
        [(ngModel)]="selectedUsuario" name="selectedUsuario" [searchable]="true" required>
      </ng-select>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <label for="fecha_alta_beneficio" class="form-label">Fecha de alta</label><input type="date" class="form-control"
        id="fecha_alta_beneficio" name="fecha_alta_beneficio" [(ngModel)]="fecha_alta_beneficio" required>
    </div>
    <div class="col-1 align-self-end">
      <button class="btn btn-dark" type="submit">Guardar</button>
    </div>
  </div>
  <br>
  <div class="row">
    <div class="col-8">
      <!-- SELECT USUARIOS ASIGNADOS -->
      <label for="">Usuarios asignados</label>
      <ng-select [items]="rows" placeholder="Seleccione un usuario asignado" [(ngModel)]="rows" name="rows"
        bindLabel="usuario" bindValue="usuario">
      </ng-select>
    </div>
  </div>
</form>
