<form ngNativeValidate (submit)="CU()">
    <div class="row">
        <div class="col-4">
            <label for="Ubicacion" class="form-label">Ubicacion Actual</label>
            <ng-select [items]="Ubicaciones" bindLabel="UBICACION" bindValue="UBICACION_ID" id="Ubicacion"
                name="Ubicacion" [(ngModel)]="UBICACION" (change)="checkUbicacion()" required
                [disabled]="ESTADO"></ng-select>
        </div>
        <div class="col-4">
            <label for="Ubicacion2" class="form-label">Ubicacion Destino</label>
            <ng-select [items]="Ubicaciones" bindLabel="UBICACION" bindValue="UBICACION_ID" id="Ubicacion2"
                name="Ubicacion2" [(ngModel)]="UBICACION2" (change)="checkUbicacion()" required
                [disabled]="ESTADO"></ng-select>
        </div>
    </div>
    <div class="row">
        <div class="col-2">
            <label for="Empresa" class="form-label">#Empresa</label>
            <input type="text" class="form-control" id="Empresa" name="Empresa" [(ngModel)]="EMPRESA" disabled required>
        </div>
        <div class="col-2">
            <label for="Tienda" class="form-label">#Tienda</label>
            <input type="text" class="form-control" id="Tienda" name="Tienda" [(ngModel)]="TIENDA" disabled required>
        </div>
        <div class="col-2">
            <label for="Caja" class="form-label">#Caja</label>
            <input type="text" class="form-control" id="Caja" name="Caja" [(ngModel)]="CAJA" disabled required>
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <label for="Serial1" class="form-label">Serial T2</label>
            <input type="text" class="form-control" id="Serial1" name="Serial1" [(ngModel)]="SERIAL1"
                [disabled]="ESTADO">
        </div>
        <div class="col-4">
            <label for="Serial2" class="form-label">Serial UPS</label>
            <input type="text" class="form-control" id="Serial2" name="Serial2" [(ngModel)]="SERIAL2"
                [disabled]="ESTADO">
        </div>
        <div class="col-4">
            <label for="Serial3" class="form-label">Serial Scanner</label>
            <input type="text" class="form-control" id="Serial3" name="Serial3" [(ngModel)]="SERIAL3"
                [disabled]="ESTADO">
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <label for="Serial4" class="form-label">Serial Router</label>
            <input type="text" class="form-control" id="Serial4" name="Serial4" [(ngModel)]="SERIAL4"
                [disabled]="ESTADO">
        </div>
        <div class="col-4">
            <label for="Serial5" class="form-label">Serial SIM</label>
            <input type="text" class="form-control" id="Serial5" name="Serial5" [(ngModel)]="SERIAL5"
                [disabled]="ESTADO">
        </div>
    </div>
    <div class="row">
        <div class="col-4">
            <label for="Informador" class="form-label">Informador</label>
            <input type="text" class="form-control" id="Informador" name="Informador" [(ngModel)]="INFORMADOR"
                [disabled]="ESTADO">
        </div>
        <div class="col-8">
            <label for="Comentario" class="form-label">Comentario</label>
            <textarea class="form-control" id="Comentario" name="Comentario" [(ngModel)]="COMENTARIO"
                [disabled]="ESTADO" rows="4"></textarea>
        </div>
    </div>
    <div class="row">
        <div class="col-2 align-self-end">
            <button type="submit" class="btn btn-dark" [disabled]="ESTADO">Guardar</button>
        </div>
        <div *ngIf="estado3" class="col-2 align-self-end">
            <strong>Cargando...</strong>
            <div class="spinner-border" role="status">
            </div>
        </div>
    </div>
</form>