import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Resp2 } from '../../models/Resp';

@Injectable({
  providedIn: 'root',
})
export class PmUsuarioService {
  columns: any;
  rows: any;
  message: string;

  constructor(private api: ApiService) {}

  listUsuario(body = {}) {
    return new Promise((res, req) => {
      this.api.Servicio('post', 'read/beneficio_usuario', body).subscribe(
        ({ status, data, message }: Resp2) => {
          this.columns = [
            { prop: 'id_beneficio' },
            { prop: 'usuario' },
            { prop: 'fecha_alta_beneficio' },
          ];
          this.rows = data;
          res('Exito');
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }

  readUsuario(filtro = {}) {
    return new Promise((res, req) => {
      this.api.Servicio('post', 'read/beneficio_usuario', filtro).subscribe(
        ({ status, data, message }: Resp2) => {
          this.columns = [
            { prop: 'id_beneficio' },
            { prop: 'usuario' },
            { prop: 'fecha_alta_beneficio' },
          ];
          this.rows = data;
          res('Exito');
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }

  CU(metodo = 'post', endpoint, json = {}) {
    return new Promise((res, req) => {
      this.api
        .Servicio(metodo, `${endpoint}/beneficio_usuario`, { data: json })
        .subscribe(
          ({ status, data, message }: Resp2) => {
            this.message = message;
            res('Exito');
          },
          (err) => {
            console.log(err);
          }
        );
    });
  }
}
