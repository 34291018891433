import { Component } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ApiService } from '../../shared/services/api.service';
import { Usuario, UsuarioInit, ListaUsuarios, usuariosInit } from '../../shared/models/Usuarios.model';
import { tiendaInit } from '../../shared/models/Tiendas.model';
import { Resp } from '../../shared/models/Resp';
import { ToastrService } from 'ngx-toastr';
import * as Swal from 'sweetalert2';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css'],
})
export class UsuariosComponent {
  //Constructor
  constructor(private api: ApiService, private toast: ToastrService) {
    this.listUsuarios();
  }

  //Variables
  buscador: string = '';
  lista_usuarios: ListaUsuarios[] = [];
  usuario: Usuario;
  rows: any;
  columns: any;
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  recarga: number = 0;
  empresa2: number;
  tienda2: number;
  estado: boolean = false;
  estado2: string = '';
  estado3: string = '';

  //Funciones
  newUsuario() {
    Swal.default
      .fire({
        icon: 'question',
        title: 'Ingresa el codigo de empresa',
        inputPlaceholder: 'Código de empresa',
        input: 'text',
        showCancelButton: true,
      })
      .then((data) => {
        if (data.value) {
          this.newUsuario2(data.value);
        }
      });
  }
  newUsuario2(empresa) {
    Swal.default
      .fire({
        icon: 'question',
        title: 'Ingresa el codigo de tienda',
        inputPlaceholder: 'Código de tienda',
        input: 'text',
        showCancelButton: true,
      })
      .then((data) => {
        if (data.value) {
          this.newUsuario3(empresa, data.value);
        }
      });
  }
  newUsuario3(empresa, tienda) {
    this.api
      .Post('Empresa/Tiendas', {
        ...tiendaInit,
        ACCION: 'R',
        EMPRESA_NUEVA: empresa,
        TIENDA_NUEVA: tienda,
      })
      .subscribe(
        ({ data, status, message }: Resp) => {
          if (data[0] != null) {
            this.usuario = {
              ...UsuarioInit,
              EMPRESA: empresa,
              TIENDA: tienda,
            };
            this.Invisible();
            this.recarga += 1;
            this.estado2 = '1';
            this.estado = false;
          } else {
            this.toast.warning('Ingresa una tienda valida', '', {
              closeButton: true,
              timeOut: 3000,
              progressAnimation: 'decreasing',
              progressBar: true,
            });
          }
        },
        (err) => {
          this.estado = true;
        }
      );
  }
  listUsuarios() {
    var buscador2: string;
    if (this.buscador == '') {
      buscador2 = '0';
    } else {
      buscador2 = this.buscador;
    }
    this.api
      .Post('Empresa/Usuarios', {
        ...usuariosInit,
        EMPRESA: buscador2,
        USUARIO: buscador2,
      })
      .subscribe(({ data, status, message }: Resp) => {
        this.lista_usuarios = [];
        data.forEach((dato: any) => {
          this.lista_usuarios.push(dato);
        });
        this.rows = this.lista_usuarios;
        this.columns = [
          { prop: 'EMPRESA', name: '#Empresa' },
          { prop: 'TIENDA', name: '#Tienda' },
          { prop: 'USUARIO', name: 'Nombre de usuario' },
          { prop: 'TDA_NOMBRE', name: 'Nombre de tienda' },
        ];
      });
  }
  readUsuario(buscador2) {
    this.api
      .Post('Empresa/Usuarios', {
        ...usuariosInit,
        ACCION: 'R',
        USUARIO: buscador2.selected[0].USUARIO,
      })
      .subscribe(({ data, status, message }: Resp) => {
        this.usuario = data[0];
        this.Invisible();
        this.recarga += 1;
        this.estado2 = '2';
      });
  }
  Recarga($event) {
    this.usuario = null;
    this.recarga += 1;
    this.listUsuarios();
    if ($event == '') {
      this.toast.success('Ingresado correctamente', 'Exíto', {
        closeButton: true,
        timeOut: 3000,
        progressAnimation: 'decreasing',
        progressBar: true,
      });
    } else {
      this.toast.error($event.data[0].ERROR, 'Fallido', {
        closeButton: true,
        timeOut: 3000,
        progressAnimation: 'decreasing',
        progressBar: true,
      });
    }
  }
  Invisible() {
    let invisible: HTMLElement = document.getElementById(
      'invisible'
    ) as HTMLElement;
    invisible.click();
  }
}
