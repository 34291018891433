import { Injectable } from "@angular/core";
import { Resp2 } from "../../models/Resp";
import { ApiService } from "../api.service";
import { Beneficio } from "../../models/Pm-Beneficios.model";

@Injectable({
    providedIn: 'root',
  })
export class PmDetalleService{
//Constructor
constructor(private api: ApiService){

}

//Variables
detalles:any;
detalle:any;
message:string;

//Funciones
listDetalle(id_beneficio){
    return new Promise((res,rej) => {    
        this.api
        .Servicio('post','read/beneficio_detalle', {id_beneficio:id_beneficio})
        .subscribe(
          ({ status, data, message }: Resp2) => {
            this.detalles = data;
            res("Exito")
          },
          (err) => {
           console.log(err);
          });
        });
}
readDetalle(id_beneficio,id_rango){
    return new Promise((res,rej) => {    
        this.api
        .Servicio('post','read/beneficio_detalle', {id_beneficio:id_beneficio,id_rango:id_rango})
        .subscribe(
          ({ status, data, message }: Resp2) => {
            this.detalle = data;
            res("Exito")
          },
          (err) => {
           console.log(err);
          });
        });
}
CU(metodo,endpoint,json){
    return new Promise((res,rej) => {    
        this.api
        .Servicio(metodo,endpoint + '/beneficio_detalle', json)
        .subscribe(
          ({ status, data, message }: Resp2) => {
            this.message = message;
            res("Exito")
          },
          (err) => {
           console.log(err);
           res('Fallido');
          });
        });
}
}