export type TAcreditacionTienda = {
  ACCION: 'L' | 'R' | 'C' | 'U' | 'D';
  EMPRESA: string;
  TIENDA: string;
  NOMBRECOMERCIAL: string;
  DIRECCION: string;
  MUNICIPIO: string;
  DEPARTAMENTO: string;
  SAT: string;
};

export const acreditacionTiendaInit: TAcreditacionTienda = {
  ACCION: 'L',
  EMPRESA: '',
  TIENDA: '',
  NOMBRECOMERCIAL: '',
  DIRECCION: '',
  MUNICIPIO: '',
  DEPARTAMENTO: '',
  SAT: '',
};
