import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})
export class PrincipalComponent implements OnInit{
  //Constructor
  constructor(private cookie:CookieService){      
  }
  ngOnInit(): void {
    this.NOMBRE = this.cookie.get('NOM_SESSION');
  }

  //Variables
  NOMBRE:string = '';
}
