import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private token: string = '';
  private baseURL: string = '';
  private baseURL2: string = '';

  constructor(private httpClient: HttpClient, private cookie: CookieService) {
    this.token = this.cookie.get('TOKEN_SESSION') ?? 'EMPTY';
    this.baseURL = environment.baseURL;
    this.baseURL2 = environment.baseURL2;
  }

  // Get<T>(endpoint: string, params: Record<string, any> = {}, headers: Record<string, any>): Observable<any> {}
  Get(
    endpoint: string,
    params: Record<string, any> = {},
    headers: Record<string, any> = {}
  ): Observable<any> {
    return this.httpClient.get(this.baseURL + endpoint, {
      params: params,
      headers: {
        'Content-Type': 'application/json',
        TOKEN: this.token,
        ...headers,
      },
    });
  }

  // Post<T>(endpoint: string, json: any, headers: Record<string, any>): Observable<any> {}
  Post(
    endpoint: string,
    json: any,
    headers: Record<string, any> = {}
  ): Observable<any> {
    return this.httpClient.post(this.baseURL + endpoint, json, {
      headers: {
        'Content-Type': 'application/json',
        TOKEN: this.token,
        ...headers,
      },
    });
  }

  PostDownload(
    endpoint: string,
    json: any,
    headers: Record<string, any> = {}
  ): Observable<any> {
    return this.httpClient.post(this.baseURL + endpoint, json, {
      headers: {
        'Content-Type': 'text/csv',
        TOKEN: this.token,
        ...headers,
      },
      responseType: 'blob'
    });
  }

  PostLoad(
    endpoint: string,
    archivo:File,
    headers: Record<string, any> = {},
  ): Observable<any> {
    const formData = new FormData();
    formData.append('csvFile', archivo);
    return this.httpClient.post(this.baseURL + endpoint, formData, {
      headers: {
        TOKEN: this.token,
        ...headers,
      },
    });
  }

  Servicio(
    metodo: string,
    endpoint: string,
    json: any,
    headers: Record<string, any> = {}
  ): Observable<any> {
    if (metodo == 'post') {
      return this.httpClient.post(this.baseURL2 + endpoint, json, {
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
      });
    } else if (metodo == 'patch') {
      return this.httpClient.patch(this.baseURL2 + endpoint, json, {
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
      });
    } else {
      return null;
    }
  }
}
