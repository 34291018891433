export interface ListaUsuarios {
    EMPRESA:string;
    TIENDA:string;
    USUARIO:string;
    TDA_NOMBRE:string;
}

export interface Usuario{
    EMPRESA:string;
    TIENDA:string;
    GRUPO:string;
    USUARIO:string;
    PASS:string;
    REPORTES:string;
}

export const UsuarioInit: Usuario = {
    EMPRESA:"",
    TIENDA:"",
    GRUPO:"",
    USUARIO:"",
    PASS:"",
    REPORTES:""
}

export type TUsuarios = {
    ACCION: 'L' | 'R' | 'C' | 'U' ;
    EMPRESA: string;
    TIENDA: string;
    GRUPO: string;
    USUARIO: string;
    PASS: string;
    USUARIO2: string;
  };
  
  export const usuariosInit: TUsuarios = {
    ACCION: 'L',
    EMPRESA: '',
    TIENDA: '',
    GRUPO: '',
    USUARIO: '',
    PASS: '',
    USUARIO2: '',
  };
  