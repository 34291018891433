import { Component, ElementRef } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ListaProductos, Producto, ProductoInit, productoInit, } from '../../shared/models/Productos.model';
import { Resp } from '../../shared/models/Resp';
import { ApiService } from '../../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import * as Swal from 'sweetalert2';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css'],
})
export class ProductosComponent {
  //Constructor
  constructor(private api: ApiService, private toast: ToastrService, private element: ElementRef) {
    this.listProductos();
  }

  //Variables
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  lista_productos: ListaProductos[] = [];
  producto: Producto;
  rows: any;
  columns: any;
  recarga: number = 0;
  tienda2: number;
  estado: boolean = false;
  buscador: string = '';
  estado2: string = '';
  estado3: string = '';

  //Funciones
  listProductos() {
    var buscador2: string;
    if (this.buscador == '') {
      buscador2 = '';
    } else {
      buscador2 = this.buscador;
    }
    this.api
      .Post('Sistema/Productos', { ...productoInit, BARRA: buscador2 })
      .subscribe(({ data }) => {
        this.lista_productos = [];
        data.forEach((dato: any) => {
          this.lista_productos.push(dato);
        });
        this.rows = this.lista_productos;
        this.columns = [
          { prop: 'COD_BARRAS' },
          { prop: 'SKU' },
          { prop: 'DES_SKU' },
          { prop: 'MARCADES' },
          { prop: 'DES_CAT_1' },
          { prop: 'DES_SUBCAT1' },
          { prop: 'DES_SSUBCAT' },
        ];
      });
  }
  newProducto() {
    this.api
      .Post('Sistema/Productos', {
        ...productoInit,
        ACCION: 'Z',
      })
      .subscribe((data) => {
        this.producto = {
          ...ProductoInit,
          SKU: data.data[0].SKU + '',
        }
        this.Invisible();
        this.recarga += 1;
        this.estado2 = '1';
      });
  }
  newCodigoCorto() {
    this.api
      .Post('Sistema/Productos', {
        ...productoInit,
        ACCION: 'Z',
      })
      .subscribe((data) => {
        this.producto = {
          ...ProductoInit,
          COD_BARRAS: data.data[0].SKU + '',
          SKU: data.data[0].SKU + '',
          MARCA: "00872",
          COD_CAT_1: "00017",
          COD_SUB_CAT1: "00208",
          COD_SSUBCAT: "00332",
        }
        this.Invisible();
        this.recarga += 1;
        this.estado2 = '3';
      });
  }
  readProducto(buscador2) {
    this.api
      .Post('Sistema/Productos', {
        ...productoInit,
        ACCION: 'R',
        SKU: buscador2.selected[0].SKU,
      })
      .subscribe(({ data }) => {
        this.producto = data[0];
        this.Invisible();
        this.recarga += 1;
        this.estado2 = '2';
      });
  }
  Recarga($event) {
    this.producto = null;
    this.recarga += 1;
    this.listProductos();
    if ($event == '') {
      this.toast.success('Ingresado correctamente', 'Exíto', {
        closeButton: true,
        timeOut: 3000,
        progressAnimation: 'decreasing',
        progressBar: true,
      });
    } else {
      this.toast.error($event[0].ERROR, 'Fallido', {
        closeButton: true,
        timeOut: 3000,
        progressAnimation: 'decreasing',
        progressBar: true,
      });
    }
  }
  Invisible() {
    let invisible: HTMLElement = document.getElementById(
      'invisible'
    ) as HTMLElement;
    invisible.click();
  }
  createMarcas() {
    const modal = this.element.nativeElement.querySelector('#staticBackdrop');
    modal.style.display = 'none';
    Swal.default
      .fire({
        icon: 'question',
        title: '¿Deseas crear la marca?',
        inputPlaceholder: 'Ingresa el nombre de la marca',
        input: 'text',
        showCancelButton: true,
      })
      .then((data) => {
        if (data.value) {
          this.api
            .Post('Sistema/Datos/5/' + data.value + '/0', {})
            .subscribe(({ data, status, message }: Resp) => {
              this.Recarga(data);
              modal.style.display = 'flex';
            });
        }
        modal.style.display = 'flex';
      });
  }
}
