import { Component } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { PmBeneficiosService } from '../../../shared/services/pushmoney/pm-beneficios.service';
import { PmTiposService } from '../../../shared/services/pushmoney/pm-tipos.service';
import { PmPatrocinadorService } from '../../../shared/services/pushmoney/pm-patrocinador.service';
import { PmDetalleService } from '../../../shared/services/pushmoney/pm-detalles.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pm-beneficios',
  templateUrl: './pm-beneficios.component.html',
  styleUrls: ['./pm-beneficios.component.css'],
})
export class PmBeneficiosComponent {
  //Constructor
  constructor(
    private service: PmBeneficiosService,
    private service2: PmTiposService,
    private service3: PmPatrocinadorService,
    private service4: PmDetalleService,
    private toast: ToastrService
  ) {
    this.listTipo();
    this.listPatrocinador();
    this.listBeneficio();
  }

  //Variables
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  columns: any;
  rows: any;
  tipos: any;
  patrocinadores: any;
  beneficio: any;
  detalles: any;
  estado: string = '0';
  id: string;
  recarga: number = 0;
  nombre: string;
  tipo: string;
  patrocinador: string;
  fechai: string;
  fechaf: string;
  maximo: string;
  message: string = '';
  opcion: Number;
  id_beneficio: string;
  id_rango: string;
  buscador: string = '';

  //Funciones
  async listTipo() {
    try {
      await this.service2.listTipo();
      this.tipos = this.service2.rows;
      this.tipos.forEach(tipo => {
        tipo["beneficio"] = tipo["id_tipo_beneficio"] + " " + tipo["tipo_beneficio"];
      });
    } catch (error) {
      console.log(error);
    }
  }
  async listPatrocinador() {
    try {
      await this.service3.listPatrocinador();
      this.patrocinadores = this.service3.rows;
    } catch (error) {
      console.log(error);
    }
  }
  async listBeneficio() {
    try {
      await this.service.listBeneficio();
      this.columns = this.service.columns;
      this.rows = this.service.rows;
      this.rows.forEach(row => {
        var fecha =  new Date(row["fecha_inicial"]);
        row["fecha_inicial"] = fecha.getDate() + "/" + (fecha.getMonth()+1) + "/" + fecha.getFullYear();
        fecha =  new Date(row["fecha_final"]);
        row["fecha_final"] = fecha.getDate() + "/" + (fecha.getMonth()+1) + "/" + fecha.getFullYear();
      });
    } catch (error) {
      console.log(error);
    }
  }
  async listDetalle() {
    try {
      await this.service4.listDetalle(this.id);
      this.detalles = this.service4.detalles;
    } catch (error) {
      console.log(error);
    }
  }
  newBeneficio() {
    this.id = '';
    this.nombre = '';
    this.tipo = '';
    this.patrocinador = '';
    this.fechai = '';
    this.fechaf = '';
    this.maximo = '';
    this.estado = '1';
    this.opcion = 0;
  }
  async createDetalle(){
    var status;
    const result: any = await this.service.lastBeneficio();
    for (let i = 0; i < +this.maximo; i++) {
      status = await this.service4.CU('post', 'create', {
        data: [
          {
            id_beneficio: result[0].id_beneficio,
            id_rango: i + 1,
            valor_meta_rango: 0,
            valor_beneficio: 0,
            descripcion_beneficio: this.nombre,
          },
        ],
      });
      if(status=='Exito'){
        this.toast.success(this.service.message,"Exito en detalle", {closeButton : true, timeOut : 3000 ,progressAnimation : 'decreasing', progressBar : true});
      }else if (status=='Fallido'){
        this.toast.error(this.service.message,"Fallido en detalle", {closeButton : true, timeOut : 3000 ,progressAnimation : 'decreasing', progressBar : true});
      }
    }
  }
  async readBeneficio(event) {
    try {
      await this.service.readBeneficio(event);
      this.beneficio = this.service.beneficio;
      this.id = this.beneficio[0].id_beneficio;
      this.recarga += 1;
      this.nombre = this.beneficio[0].beneficio;
      this.tipo = this.beneficio[0].id_tipo_beneficio;
      this.patrocinador = this.beneficio[0].id_patrocinador;
      var fecha1 = new Date(this.beneficio[0].fecha_inicial);
      this.fechai = fecha1.toISOString().split('T')[0];
      var fecha2 = new Date(this.beneficio[0].fecha_final);
      this.fechaf = fecha2.toISOString().split('T')[0];
      this.maximo = this.beneficio[0].maximo_beneficio;
      this.estado = '1';
      this.listDetalle();
    } catch (error) {
      console.log(error);
    }
  }
  async CU() {
    var endpoint;
    var metodo;
    if (this.id == '') {
      endpoint = 'create';
      metodo = 'post';
    } else {
      endpoint = 'update';
      metodo = 'patch';
    }
    var status = await this.service.CU(metodo, endpoint, {
        data: [
          {
            id_beneficio: this.id,
            beneficio: this.nombre,
            id_tipo_beneficio: this.tipo,
            id_patrocinador: this.patrocinador,
            fecha_inicial: this.fechai,
            fecha_final: this.fechaf,
            maximo_beneficio: this.maximo,
          },
        ],
      });
      if(status=='Exito'){
        if(endpoint=='create'){
          await this.createDetalle();
        }
        this.toast.success(this.service.message,"Exito", {closeButton : true, timeOut : 3000 ,progressAnimation : 'decreasing', progressBar : true});
        this.newBeneficio();
        this.listBeneficio();
        let close:HTMLElement= document.getElementById('Close') as HTMLElement;
        close.click();
      }else if (status=='Fallido'){
        this.toast.error(this.service.message,"Fallido", {closeButton : true, timeOut : 3000 ,progressAnimation : 'decreasing', progressBar : true});
      }
  }
  Recarga($event) {
    this.message = $event;
    this.toast.info(this.message,"", {closeButton : true, timeOut : 3000 ,progressAnimation : 'decreasing', progressBar : true});
    this.listDetalle();
    this.id_beneficio = '';
    this.id_rango = '';
  }
  Opciones(opcion) {
    this.opcion = opcion;
  }
  Select($event){
    this.id_beneficio = $event.id_beneficio;
    this.id_rango = $event.id_rango;
    this.recarga += 1;
  }
}
