export type TCajas = {
  ACCION: 'L' | 'R' | 'C' | 'U';
  EMPRESA: string;
  TIENDA: string;
  CAJA: string;
};

export const cajasInit: TCajas = {
  ACCION: 'L',
  EMPRESA: '',
  TIENDA: '',
  CAJA: '',
};
