export type TAcreditacionEmpresa = {
  ACCION: 'L' | 'R' | 'C' | 'U' | 'D';
  EMPRESA: string;
  NOM_EMPRESA: string;
  NIT: string;
  USUARIO: string;
  DIRECCION: string;
  LLAVE: string;
  FIRMA: string;
  FRASE1: string;
  FRASE2: string;
  FEL: string;
};

export const acreditacionEmpresaInit: TAcreditacionEmpresa = {
  ACCION: 'L',
  EMPRESA: '',
  NOM_EMPRESA: '',
  NIT: '',
  USUARIO: '',
  DIRECCION: '',
  LLAVE: '',
  FIRMA: '',
  FRASE1: '',
  FRASE2: '',
  FEL: '',
};
