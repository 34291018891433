<form ngNativeValidate (submit)="CU()">
  <div class="row">
    <div class="col-3">
      <label for="Barra" class="form-label">Barra</label>
      <input type="text" class="form-control" id="Barra" name="Barra" [(ngModel)]="COD_BARRAS" (blur)="readBarra()"
        required [disabled]="this.estado2!='1'">
    </div>
    <div *ngIf="estado" class="col-2 alert alert-danger text-center mt-2">
      ¡Barra ya existente!
    </div>
    <div class="col-1">
      <label for="SKU" class="form-label">SKU</label>
      <input type="text" class="form-control" id="SKU" name="SKU" [(ngModel)]="SKU" disabled required>
    </div>
    <div class="col-4">
      <label for="Descripcion" class="form-label">Descripcion</label>
      <input type="text" class="form-control" id="Descripcion" name="Descripcion" [(ngModel)]="DES_SKU" required>
    </div>
    <div class="col-4">
      <div class="container">
        <label for="Marcas" class="form-label">Marcas</label>
        <button type="button" class="btn btn-dark ms-2" style="background-color: black;" (click)="createMarcas()">
          <i class="bi bi-plus"></i>
        </button>
      </div>
      <ng-select [items]="Marcas" bindLabel="MARCADES" bindValue="MARCA" id="Marca2" name="Marca2" [(ngModel)]="MARCA"
        required [disabled]="this.estado2 == '3'"></ng-select>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <label for="Categoria" class="form-label">Categoria</label>
      <ng-select [items]="Categorias" bindLabel="DES_CAT_1" bindValue="COD_CAT_1" id="Categoria" name="Categoria"
        [(ngModel)]="COD_CAT_1" required (change)="listSubCategorias()" [disabled]="this.estado2 == '3'"></ng-select>
    </div>
    <div class="col-4">
      <label for="SubCategoria" class="form-label">SubCategoria</label>
      <ng-select [items]="SubCategorias" bindLabel="DES_SUBCAT1" bindValue="COD_SUB_CAT1" id="SubCategoria"
        name="SubCategoria" [(ngModel)]="COD_SUB_CAT1" required (change)="listSubCategorias2()"
        [disabled]="this.estado2 == '3'"></ng-select>
    </div>
    <div class="col-4">
      <label for="SubCategoria2" class="form-label">SubCategoria2</label>
      <ng-select [items]="SubCategorias2" bindLabel="DES_SSUBCAT" bindValue="COD_SSUBCAT" id="SubCategoria2"
        name="SubCategoria2" [(ngModel)]="COD_SSUBCAT" required [disabled]="this.estado2 == '3'"></ng-select>
    </div>
  </div>
  <div class="row">
    <!-- <div class="col-2">
      <label for="Volumen" class="form-label">Volumen</label>
      <input type="text" class="form-control" id="Volumen" name="Volumen" [(ngModel)]="VOLUMEN" required>
    </div>
    <div class="col-2">
      <label for="Sabor" class="form-label">Sabor/Aroma</label>
      <input type="text" class="form-control" id="Sabor" name="SABOR" [(ngModel)]="SABOR">
    </div>
    <div class="col-4">
      <label for="Fabricante2" class="form-label">Fabricante</label>
      <ng-select [items]="Fabricantes" bindLabel="FABRICANTE" bindValue="ID" id="Fabricante2" name="Fabricante2"
        [(ngModel)]="FABRICANTE2"></ng-select>
    </div>
    <div class="col-2">
      <label for="Empaque" class="form-label">Empaque</label>
      <input type="text" class="form-control" id="Empaque" name="Empaque" [(ngModel)]="EMPAQUE" required>
    </div> -->
    <div class="col-1 align-self-end">
      <button type="submit" class="btn btn-dark" id="guardar">Guardar</button>
    </div>
    <div *ngIf="estado3" class="col-2 align-self-end">
      <strong>Cargando...</strong>
      <div class="spinner-border" role="status">
      </div>
    </div>
  </div>
</form>