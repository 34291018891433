export interface ListaPrecios {
    EMPRESA: string;
    TIENDA: string;
    LISTA_TIENDA: string;
    TDA_NOMBRE: string;
}

export type TListaPrecios = {
    ACCION: 'L' | 'R' | 'C' | 'U';
    EMPRESA: string;
    TIENDA: string;
    LISTA: string;
};

export const listaPreciosInit: TListaPrecios = {
    ACCION: 'L',
    EMPRESA: '',
    TIENDA: '',
    LISTA: '',
};
