import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from './shared/services/api.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(
    private cookie: CookieService,
    private router: Router,
    private api: ApiService
  ) {
    if (this.cookie.get('TOKEN_SESSION') == '') {
      this.router.navigate(['/Login']);
    } else {
      this.token = this.cookie.get('TOKEN_SESSION');
      this.postPermisos(this.cookie.get('TOKEN_SESSION'));
    }
  }
  ngOnInit(): void {
  }

  nivel1: String[] = [];
  nivel2: String[] = [];
  nivel3: String[] = [];
  nivel4: String[] = [];
  nivel5: String[] = [];
  permisos = [];
  suscriber: Subscription;
  token:string = '';

  deleteCookie() {
    this.cookie.deleteAll();
  }
  postPermisos(token: string) {
    var a = 0;
    this.api
      .Get('Login/CheckPermisos', {}, { TOKEN: token })
      .subscribe((data) => {
        data.forEach((dato: any) => {
          this.permisos.push(dato.PERMISO);
          if (dato.NIVEL == '1') {
            this.nivel1.push(dato.PERMISO);
          } else if (dato.NIVEL == '2') {
            this.nivel2.push(dato.PERMISO);
          } else if (dato.NIVEL == '3') {
            this.nivel3.push(dato.PERMISO);
          } else if (dato.NIVEL == '4') {
            this.nivel4.push(dato.PERMISO);
          } else if (dato.NIVEL == '5') {
            this.nivel5.push(dato.PERMISO);
          }
        });
        var ruta = (window.location.pathname.substring(1, window.location.pathname.length))
        ruta = ruta.replaceAll('%20',' ');
        this.permisos.forEach(element => {
          if(element == ruta || ruta == "Principal"){
            a=1;
          }
        });
        if(a==0){
          this.router.navigate(['/Error']);
        }
      });
  }
}
