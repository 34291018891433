<div class="row d-flex align-items-end" style="height: 100px;">
  <div class="col-3">
    <label for="Plantilla" class="form-label">Plantilla</label>
    <ng-select [items]="Plantillas" bindLabel="TABLE_NAME" bindValue="TABLE_NAME" id="Plantilla" name="Plantilla"
      [(ngModel)]="TABLE_NAME"></ng-select>
  </div>
  <div class="col-2"><button class="btn btn-dark" (click)="readPlantilla()">Descargar Plantilla</button></div>
  <div class="col-3">
    <label for="CSV" class="form-label">Seleccionar Plantilla</label>
    <input #Csv class="form-control" type="file" id="CSV" (change)="CSV($event)">
  </div>
  <div class="col-1 align-self-end">
    <button type="button" class="btn btn-dark" (click)="Verificar()">Guardar</button>
  </div>
</div>

<br>

<table class="table">
  <thead class="table-dark">
    <tr>
      <th *ngFor="let column of columns">{{ column.name }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of rows">
      <td *ngFor="let column of columns">{{ row[column.prop] }}</td>
    </tr>
  </tbody>
</table>