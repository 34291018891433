import { Component, ElementRef, ViewChild } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ApiService } from '../../shared/services/api.service';
import { Toast, ToastrService } from 'ngx-toastr';
import * as Swal from 'sweetalert2';
import * as Papa from 'papaparse';


@Component({
  selector: 'app-plantillas',
  templateUrl: './plantillas.component.html',
  styleUrls: ['./plantillas.component.css'],
})
export class PlantillasComponent {
  //Constructor
  constructor(private api: ApiService, private toast: ToastrService) {
    this.listPlantillas();
  }

  //Variables
  @ViewChild('Csv') Csv: ElementRef;
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  Plantillas: any;
  rows: any[] = [];
  columns: any[] = [];
  file: any;
  TABLE_NAME: string = '';
  estado: boolean;

  //Funciones
  listPlantillas() {
    this.api.Get('Tableros/Plantilla').subscribe((data) => {
      this.Plantillas = data.data;
    });
  }
  readPlantilla(){
    this.api.PostDownload('Tableros/CSV/' + this.TABLE_NAME, {}).subscribe(data => {
      const blob = new Blob([data], { type: 'text/csv' });
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = 'plantilla.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  }
  updatePlantilla(){
    this.api.PostLoad('Tableros/JSON/' + this.TABLE_NAME, this.file.files[0]).subscribe(data =>{
      if (data.data == "Ingresado") {
        this.toast.success('Ingresado correctamente', 'Exíto', {
          closeButton: true,
          timeOut: 3000,
          progressAnimation: 'decreasing',
          progressBar: true,
        });
        this.rows = [];
        this.columns = [];
        this.TABLE_NAME = null;
        this.Csv.nativeElement.value = '';
      } else {
        this.toast.error(data.data[0].ERROR, 'Fallido', {
          closeButton: true,
          timeOut: 3000,
          progressAnimation: 'decreasing',
          progressBar: true,
        });
      }
    })
  }
  CSV($event){
    this.file = $event.target;
    let reader = new FileReader()
    reader.readAsText(this.file.files[0]);
    reader.onload = ()  =>{
      let csv = reader.result;
      let data = (<string>csv).split(/\r\n|\n/); 
      Papa.parse(data.join('\n'),{
        header: true,
        complete: (result) =>{
          let columns = Object.keys(result.data[0]);
          columns.forEach(column => {
            this.columns.push({ prop: column, name: column })
          });
          this.rows = result.data;
        }
      })
    }
  }
  Verificar(){
    if(this.TABLE_NAME == ''){
      Swal.default
      .fire({
        icon: 'warning',
        title: 'Debe seleccionar plantilla a subir',
      })
    }
    else if(this.rows.length == 0){
      Swal.default
      .fire({
        icon: 'warning',
        title: 'Debe subir la plantilla',
      })
    }
    else{
      this.updatePlantilla();
    }
  }
}
