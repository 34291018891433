import { Component } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ApiService } from '../../shared/services/api.service';
import { ListaInventarios, ListaInventariosInit, inventarioInit } from '../../shared/models/Inventarios.model';
import { ToastrService } from 'ngx-toastr';
import * as Swal from 'sweetalert2';

@Component({
  selector: 'app-inventarios',
  templateUrl: './inventarios.component.html',
  styleUrls: ['./inventarios.component.css'],
})
export class InventariosComponent {
  //Constructor
  constructor(
    private api: ApiService,
    private toast: ToastrService
  ) {
    this.listInventarios();
  }

  //Variables
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  lista_inventarios: ListaInventarios[] = [];
  rows: any;
  columns: any;
  estado2: boolean = false;
  estado: string = '';
  buscador: string = '';

  //Funciones
  listInventarios() {
    var buscador2: string;
    if (this.buscador == '') {
      buscador2 = '0';
    } else {
      buscador2 = this.buscador;
    }
    this.api
      .Post('Empresa/Inventarios', {
        ...inventarioInit,
        EMPRESA: buscador2,
        TIENDA: buscador2,
      })
      .subscribe((data) => {
        this.lista_inventarios = [];
        data.data.forEach((dato: any) => {
          this.lista_inventarios.push(dato);
        });
        this.rows = this.lista_inventarios;
        this.columns = [
          { prop: 'EMPRESA', name: '#Empresa' },
          { prop: 'TIENDA', name: '#Tienda' },
          { prop: 'TDA_NOMBRE', name: 'Nombre de tienda' },
        ];
      });
  }
  CU($event) {
    this.estado2 = true;
    Swal.default
      .fire({
        icon: 'warning',
        title: '¿Deseas borrar el inventario de la tienda?',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
      })
      .then((data) => {
        if (data.isConfirmed) {
          this.api
            .Post('Empresa/Inventarios', {
              ...ListaInventariosInit,
              ACCION: 'U',
              EMPRESA: $event.selected[0].EMPRESA,
              TIENDA: $event.selected[0].TIENDA,
            })
            .subscribe((data) => {
              if (data.data[0] == null) {
                this.toast.success('Borrado correctamente', 'Exíto', {
                  closeButton: true,
                  timeOut: 3000,
                  progressAnimation: 'decreasing',
                  progressBar: true,
                });
              } else {
                this.toast.error(data.data[0].ERROR, 'Fallido', {
                  closeButton: true,
                  timeOut: 3000,
                  progressAnimation: 'decreasing',
                  progressBar: true,
                });
              }
              this.estado2 = false;
            });
        }
      });
  }
}
