export interface ListaInventarios {
    EMPRESA:string;
    TIENDA:string;
    TDA_NOMBRE:string;
}

export const ListaInventariosInit: ListaInventarios = {
    EMPRESA:'',
    TIENDA:'',
    TDA_NOMBRE:''
}

export type TInventario = {
    ACCION: 'L' | 'R' | 'C' | 'U';
    EMPRESA: string;
    TIENDA: string;
  };
  
  export const inventarioInit: TInventario = {
    ACCION: 'L',
    EMPRESA: '',
    TIENDA: '',
  };
  