<br>
<form ngNativeValidate (submit)="CU()">
  <div class="row">
    <div class="col-6">
      <label for="Nombre" class="form-label">Nombre</label>
      <input type="text" class="form-control" id="Nombre" name="Nombre" [(ngModel)]="TDA_NOMBRE" required>
    </div>
    <div class="col-6">
      <label for="Direccion" class="form-label">Direccion</label>
      <input type="text" class="form-control" id="Direccion" name="Direccion" [(ngModel)]="DIRECCION" required>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <label for="Departamento" class="form-label">Departamento</label>
      <input type="text" class="form-control" id="Departamento" name="Departamento" [(ngModel)]="GFACE_FEL_DEPARTAMENTO"
        required>
    </div>
    <div class="col-3">
      <label for="Municipio" class="form-label">Municipio</label>
      <input type="text" class="form-control" id="Municipio" name="Municipio" [(ngModel)]="GFACE_FEL_MUNICIPIO"
        required>
    </div>
    <div class="col-1">
      <label for="SAT" class="form-label">SAT</label>
      <input type="text" class="form-control" id="SAT" name="SAT" [(ngModel)]="SAT_ESTABLECIMIENTO" required>
    </div>
  </div>
  <div class="row">
    <div class="col-1 align-self-end">
      <button type="submit" class="btn btn-dark">Guardar</button>
    </div>
    <div *ngIf="estado" class="col-2 align-self-end">
      <strong>Cargando...</strong>
      <div class="spinner-border" role="status">
      </div>
    </div>
  </div>
</form>