<div class="container">
    <div class="row vh-100 justify-content-center align-items-center">
        <div class="col-3">
            <form>
                <div class="input-group p-1 justify-content-center">
                    <img src="/assets/images/vikingo2.png" width="150px">
                </div>
                <div class="input-group p-1">
                    <input type="text" class="form-control text-center" placeholder="Ingresa usuario" style="height: 50px;" name="usuario" [(ngModel)]="usuario">
                </div>
                <div class="input-group p-1">
                    <input type="password" class="form-control text-center" placeholder="Ingresa contraseña" style="height: 50px;" name="pass" [(ngModel)]="pass">
                </div>
                <div class="input-group p-2 justify-content-center">
                    <button type="submit" class="btn btn-dark" (click)="PostLogin()">Guardar</button>
                </div>
                <div *ngIf="estado" class="alert alert-danger text-center">
                    ¡Credenciales incorrectas!
                </div>
            </form>
        </div>
    </div>
</div>