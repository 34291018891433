import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { Acreditacion2 } from '../../shared/models/Acreditaciones.model';
import { acreditacionTiendaInit } from '../../shared/models/AcreditacionTienda';
import { Resp } from '../../shared/models/Resp';
@Component({
  selector: 'app-acreditacion2',
  templateUrl: './acreditacion2.component.html',
  styleUrls: ['./acreditacion2.component.css'],
})
export class Acreditacion2Component implements OnInit {
  //Constructor
  constructor(private api: ApiService) { }
  ngOnInit(): void {
    this.readAcreditacionesTiendas();
  }

  //Variables
  @Input() buscador: string;
  @Input() buscador2: string;
  @Output() recarga = new EventEmitter<string>();
  EMPRESA: string = '';
  TIENDA: string = '';
  TDA_NOMBRE: string = '';
  DIRECCION: string = '';
  GFACE_FEL_MUNICIPIO: string = '';
  GFACE_FEL_DEPARTAMENTO: string = '';
  SAT_ESTABLECIMIENTO: string = '';
  acreditacion2: Acreditacion2;
  estado: boolean = false;

  //Funciones
  readAcreditacionesTiendas() {
    this.api
      .Post('Empresa/Acreditaciones_Tiendas', {
        ...acreditacionTiendaInit,
        ACCION: 'R',
        EMPRESA: this.buscador,
        TIENDA: this.buscador2,
      })
      .subscribe(({ data, status, message }: Resp) => {
        data.forEach((dato: any) => {
          this.EMPRESA = dato.EMPRESA;
          this.TIENDA = dato.TIENDA;
          this.TDA_NOMBRE = dato.TDA_NOMBRE;
          this.DIRECCION = dato.DIRECCION;
          this.GFACE_FEL_MUNICIPIO = dato.GFACE_FEL_MUNICIPIO;
          this.GFACE_FEL_DEPARTAMENTO = dato.GFACE_FEL_DEPARTAMENTO;
          this.SAT_ESTABLECIMIENTO = dato.SAT_ESTABLECIMIENTO;
        });
      });
  }
  CU() {
    this.estado = true;
    this.api
      .Post('Empresa/Acreditaciones_Tiendas', {
        ...acreditacionTiendaInit,
        ACCION: 'U',
        EMPRESA: this.EMPRESA,
        TIENDA: this.TIENDA,
        NOMBRECOMERCIAL: this.TDA_NOMBRE,
        DIRECCION: this.DIRECCION,
        MUNICIPIO: this.GFACE_FEL_MUNICIPIO,
        DEPARTAMENTO: this.GFACE_FEL_DEPARTAMENTO,
        SAT: this.SAT_ESTABLECIMIENTO + ""
      })
      .subscribe(({ data, status, message }: Resp) => {
        if (data == '') {
          data = '1';
        }
        this.recarga.emit(data);
        this.estado = false;
      });
  }
}
