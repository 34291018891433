import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { PmUsuarioService } from '../../../shared/services/pushmoney/pm-usuario.service';
import { ApiService } from '../../../shared/services/api.service';
import { Resp } from '../../../shared/models/Resp';
import { tiendaInit } from '../../../shared/models/Tiendas.model';

@Component({
  selector: 'app-pm-usuarios',
  templateUrl: './pm-usuarios.component.html',
  styleUrls: ['./pm-usuarios.component.css'],
})
export class PmUsuariosComponent {
  @Input() id_beneficio: string;
  @Output() message = new EventEmitter<string>();
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  buscador: string = '';
  columns: any;
  rows: any;
  tiendas: any;
  usuarios: any;
  estado: string = '';
  estado2: string = '';
  estado3: string = '';
  selectedTienda: any = '';
  selectedUsuario: any;
  fecha_alta_beneficio: string = '';

  constructor(private service: PmUsuarioService, private api: ApiService) {}
  ngOnInit(): void {
    this.listarUsuarioAsignados();
    // this.listarUsuarios();
    this.listarTiendas();
  }

  listarTiendas() {
    this.api
      .Post('/Empresa/Tiendas', { ...tiendaInit })
      .subscribe(({ data, status, message }: Resp) => {
        this.tiendas = data;
        this.tiendas.forEach(element => {
          element.TDA_NOMBRE = element.EMPRESA + ' ' + element.TDA_NOMBRE;
        });
      });
  }

  listarUsuarios(event?, empresa = '', tienda = '') {
    this.api
      .Post('Empresa/Usuarios', {
        ACCION: 'R2',
        USUARIO: '',
        EMPRESA: empresa,
        TIENDA: tienda,
        GRUPO : '',
        PASS : '',
        USUARIO2 : ''
      })
      .subscribe(({ data, status, message }: Resp) => {
        this.usuarios = data;
        console.log(this.usuarios);
      });
  }

  newUsuario() {
    this.id_beneficio = '';
    this.estado = '1';
  }

  async listarUsuarioAsignados() {
    try {
      await this.service.listUsuario({
        id_beneficio: this.id_beneficio,
      });
      this.columns = this.service.columns;
      this.rows = this.service.rows;
    } catch (error) {
      console.log(error);
    }
  }

  async buscarUsuario() {
    try {
      await this.service.readUsuario(
        this.buscador != ''
          ? {
              id_beneficio: this.buscador,
            }
          : undefined
      );
      this.columns = this.service.columns;
      this.rows = this.service.rows;
    } catch (error) {
      console.log(error);
    }
  }

  async readUsuario(event) {
    this.id_beneficio = event.selected[0].id_beneficio;
    this.estado = '1';
  }

  async CU(metodo: string = 'post', endpoint: string = 'create') {
    try {
      var fecha1 = new Date(this.fecha_alta_beneficio,);
      this.fecha_alta_beneficio = fecha1.toISOString().split('T')[0];
      await this.service.CU(metodo, endpoint, [
        {
          id_beneficio: this.id_beneficio,
          tienda: this.selectedTienda.TIENDA,
          empresa: this.selectedTienda.EMPRESA,
          usuario: this.selectedUsuario,
          fecha_alta_beneficio: this.fecha_alta_beneficio,
        },
      ]);
      this.message.emit(this.service.message);
      this.fecha_alta_beneficio = '';
      this.selectedTienda = {};
      this.selectedUsuario = '';
      this.listarUsuarioAsignados();
    } catch (error) {
      console.log(error);
    }
  }

  onChangeTienda(event) {
    this.listarUsuarios(undefined, event.EMPRESA, event.TIENDA);
  }
}
