export type EEmpresa = {
    ACCION: 'C' | 'Z';
    EMPRESA_NUEVA:string,
    nombre_empresa:string,
    direccion:string,
    propietario:string,
    telefono:string,
    usercajero:string,
    passcajero:string,
    tipo_tienda:string,
    codigo_jde:string,
    cuota:string,
    segmento:string,
    sector:string,
    latitud:string,
    longitud:string,
    estado:string,
    asesor:string
  };
  
  export const empresaInit: EEmpresa = {
    ACCION:"C",
    EMPRESA_NUEVA:'',
    nombre_empresa:'',
    direccion:'',
    propietario:'',
    telefono:'',
    usercajero:'',
    passcajero:'',
    tipo_tienda:'',
    codigo_jde:'',
    cuota:'',
    segmento:'',
    sector:'',
    latitud:'',
    longitud:'',
    estado:'',
    asesor:''
  };
  