<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        {{this.estado2 == '1' ? "Empresa" : "Tienda"}}
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="Close"></button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <app-tienda [tienda]="tienda" [estado2]="estado2" *appRecarga="recarga" (recarga)="Recarga($event)">
          </app-tienda>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex align-items-end" style="height: 100px;">
  <div class="col-5"> <input type="text" class="form-control"
      placeholder="Ingrese el Codigo de la empresa o Nombre de la tienda" id="Buscador" nombre="Buscador"
      [(ngModel)]="buscador" (keyup.enter)="listTiendas()"></div>
  <div class="col-3"></div>
  <div class="col-2"><button class="btn btn-dark" (click)="newEmpresa()" data-bs-toggle="modal"
      data-bs-target="#staticBackdrop">Agregar Empresa</button>
  </div>
  <div class="col-2"><button class="btn btn-dark" (click)="newTienda()">Agregar Tienda</button>
  </div>
  <div class="invisible">
    <button data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="invisible"></button>
  </div>
</div>

<br>

<div class="col">
  <ngx-datatable class="material" [rows]="rows" rowHeight="auto" [columns]="columns" [columnMode]="ColumnMode.force"
    [limit]="10" [headerHeight]="50" [footerHeight]="50" [selectionType]="SelectionType.single"
    (select)="readTienda($event)">
  </ngx-datatable>
</div>