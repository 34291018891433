import { Component } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ApiService } from '../../shared/services/api.service';
import { equiposInit } from '../../shared/models/Equipos.model';
import { cajasInit } from '../../shared/models/Cajas';
import { Equipo } from '../../shared/models/Equipos.model';
import { EquipoInit } from '../../shared/models/Equipos.model';
import { ListaEquipos } from '../../shared/models/Equipos.model';
import { Resp } from '../../shared/models/Resp';
import { ToastrService } from 'ngx-toastr';
import * as Swal from 'sweetalert2';

@Component({
  selector: 'app-equipos',
  templateUrl: './equipos.component.html',
  styleUrls: ['./equipos.component.css']
})
export class EquiposComponent {
  //Constructor
  constructor(private api: ApiService, private toast: ToastrService) {
    this.listEquipos();
  }

  //Variables
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  lista_equipos: ListaEquipos[] = [];
  equipo: Equipo;
  rows: any;
  columns: any;
  empresa2: number;
  tienda2: number;
  estado: boolean = false;
  buscador: string = '';
  estado2: string = '';
  estado3: string = '';
  recarga: number = 0;

  //Funciones
  async newEquipo() {
    var { value: formValues } = await Swal.default.fire({
      title: 'Validacion de caja',
      html:
        '<input id="swal-input1" class="form-control" placeholder="Ingresa el Codigo de empresa"> <br>' +
        '<input id="swal-input2" class="form-control" placeholder="Ingresa el Codigo de tienda"> <br>' +
        '<input id="swal-input3" class="form-control" placeholder="Ingresa el Codigo de caja">',
      focusConfirm: false,
      preConfirm: () => {
        return [
          (<HTMLInputElement>document.getElementById('swal-input1')).value,
          (<HTMLInputElement>document.getElementById('swal-input2')).value,
          (<HTMLInputElement>document.getElementById('swal-input3')).value
        ]
      }
    })
    if (formValues) {
      this.api
        .Post('Empresa/Cajas', {
          ...cajasInit,
          ACCION: 'R',
          EMPRESA: formValues[0],
          TIENDA: formValues[1],
          CAJA: formValues[2],
        })
        .subscribe(({ data, status, message }: Resp) => {
          if (data[0] != undefined) {
            this.api
              .Post('Sistema/Equipos', {
                ...equiposInit,
                ACCION: 'Z',
                EMPRESA: formValues[0],
                TIENDA: formValues[1],
                CAJA: formValues[2],
              })
              .subscribe(({ data, status, message }: Resp) => {
                if (data[0] == undefined) {
                  data[0] = {
                    ESTADO: true
                  }
                }
                if (data[0].ESTADO) {
                  this.equipo = {
                    ...EquipoInit,
                    EMPRESA: formValues[0],
                    TIENDA: formValues[1],
                    CAJA: formValues[2],
                  };
                  this.Invisible();
                  this.recarga += 1;
                  this.estado2 = '1';
                } else {
                  Swal.default.fire({
                    icon: 'warning',
                    text: 'El movimiento ' + data[0].ID + ' correspondiente a esta caja aun no se ha confirmado'
                  })
                }
              })
          } else {
            Swal.default.fire({
              icon: 'question',
              text: 'La caja ' + formValues[2] + ' no existe, ¿Deseas crearla?',
              showDenyButton: true,
              confirmButtonText: 'Si',
              denyButtonText: 'No'
            }).then((result) => {
              if (result.isConfirmed) {
                this.api
                  .Post('Empresa/Cajas', {
                    ...cajasInit,
                    ACCION: 'C',
                    EMPRESA: formValues[0],
                    TIENDA: formValues[1],
                    CAJA: formValues[2],
                  })
                  .subscribe(({ data, status, message }: Resp) => {
                    if (data == '') {
                      this.toast.success('Ingresado correctamente', 'Exíto', {
                        closeButton: true,
                        timeOut: 3000,
                        progressAnimation: 'decreasing',
                        progressBar: true,
                      });
                      this.equipo = {
                        ...EquipoInit,
                        EMPRESA: formValues[0],
                        TIENDA: formValues[1],
                        CAJA: formValues[2],
                      };
                      this.Invisible();
                      this.recarga += 1;
                      this.estado2 = '1';
                    } else {
                      this.toast.error(data.data[0].ERROR, 'Fallido', {
                        closeButton: true,
                        timeOut: 3000,
                        progressAnimation: 'decreasing',
                        progressBar: true,
                      });
                    }
                  })
              }
            })
          }
        });
    }
  }
  listEquipos() {
    var buscador2: string;
    if (this.buscador == '') {
      buscador2 = '0';
    } else {
      buscador2 = this.buscador;
    }
    this.api
      .Post('Sistema/Equipos', {
        ...equiposInit,
        EMPRESA: buscador2
      })
      .subscribe(({ data, status, message }: Resp) => {
        this.lista_equipos = [];
        data.forEach((dato: any) => {
          this.lista_equipos.push(dato);
        });
        this.rows = this.lista_equipos;
        this.rows.forEach((dato: any) => {
          if (dato["ESTADO"]) {
            dato["ESTADO"] = "Confirmado";
          } else {
            dato["ESTADO"] = "Sin confirmar";
          }
        });
        this.columns = [
          { prop: 'ID', name: '#ID' },
          { prop: 'COMBO', name: '#Combo' },
          { prop: 'EMPRESA', name: '#Empresa' },
          { prop: 'TIENDA', name: '#Tienda' },
          { prop: 'CAJA', name: '#Caja' },
          { prop: 'ESTADO', name: 'Estado del movimiento' }
        ];
      });
  }
  readEquipo(buscador2) {
    this.api
      .Post('Sistema/Equipos', {
        ...equiposInit,
        ACCION: 'R',
        EMPRESA: buscador2.selected[0].ID + '',
      })
      .subscribe(({ data, status, message }: Resp) => {
        this.equipo = data[0];
        this.Invisible();
        this.recarga += 1;
        this.estado2 = '2';
      });
  }
  Recarga($event) {
    this.equipo = null;
    this.recarga += 1;
    this.listEquipos();
    if ($event == '') {
      this.toast.success('Ingresado correctamente', 'Exíto', {
        closeButton: true,
        timeOut: 3000,
        progressAnimation: 'decreasing',
        progressBar: true,
      });
    } else {
      this.toast.error($event.data[0].ERROR, 'Fallido', {
        closeButton: true,
        timeOut: 3000,
        progressAnimation: 'decreasing',
        progressBar: true,
      });
    }
  }
  Invisible() {
    let invisible: HTMLElement = document.getElementById(
      'invisible'
    ) as HTMLElement;
    invisible.click();
  }
}
