import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent implements OnInit{
  //Constructor
  constructor(private sanitizer: DomSanitizer) {}
  ngOnInit(): void {
    this.Reporte();
  }

  //Variables
  frame:string = '';
  safeSrc: SafeResourceUrl;
  
  //Funciones
  Reporte(){
    var ruta = (window.location.pathname.substring(1, window.location.pathname.length))
    ruta = ruta.replaceAll('%20',' ');
    if(ruta=='Ventas'){
      this.frame='https://app.powerbi.com/view?r=eyJrIjoiZjNlNjk1YzctYmZjOS00YWIzLTg0MjQtNDYwY2MyODg1M2ExIiwidCI6IjFiMzUyZDFmLTdiNDMtNDdlMi05MmQxLWIxYjZiNTYzYzAwNSIsImMiOjR9';
    }else if(ruta=='Cambios Admin'){
      this.frame='https://app.powerbi.com/view?r=eyJrIjoiNDkzMGMyM2MtY2NlMy00NGQ4LTg4YmQtODllNzViNThmZDUxIiwidCI6IjFiMzUyZDFmLTdiNDMtNDdlMi05MmQxLWIxYjZiNTYzYzAwNSIsImMiOjR9';
    }
    else if(ruta=='Cambios Equipos'){
      this.frame='https://app.powerbi.com/view?r=eyJrIjoiNDU3NjBkODItM2U5MC00MzY1LWFhODYtZjVjZjIyNGJmOWJjIiwidCI6IjFiMzUyZDFmLTdiNDMtNDdlMi05MmQxLWIxYjZiNTYzYzAwNSIsImMiOjR9';
    }
    this.safeSrc = this.sanitizer.bypassSecurityTrustResourceUrl(this.frame);
  }
}
