import { Component } from '@angular/core';
import { PmPatrocinadorService } from '../../../shared/services/pushmoney/pm-patrocinador.service';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pm-patrocinadores',
  templateUrl: './pm-patrocinadores.component.html',
  styleUrls: ['./pm-patrocinadores.component.css'],
})
export class PmPatrocinadoresComponent {
  //Variables
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  columns: any;
  rows: any;
  id_patrocinador: string;
  patrocinador: string;
  message: string = '';
  buscador: string = '';

  //Constructor
  constructor(private service: PmPatrocinadorService, private toast: ToastrService) {
    this.listarPatrocinador();
  }
  //Funciones
  newPatrocinador() {
    this.id_patrocinador = '';
    this.patrocinador = '';
  }

  async listarPatrocinador() {
    try {
      await this.service.listPatrocinador();
      this.columns = this.service.columns;
      this.rows = this.service.rows;
    } catch (error) {
      console.log(error);
    }
  }

  async readPatrocinador(event) {
    this.id_patrocinador = event.selected[0].id_patrocinador;
    this.patrocinador = event.selected[0].patrocinador;
  }

  async CU() {
    let metodo = '';
    let endpoint = '';
    if (this.id_patrocinador !== '') {
      // UPDATE
      metodo = 'patch';
      endpoint = 'update';
    } else {
      // CREATE
      metodo = 'post';
      endpoint = 'create';
    }

      var status = await this.service.CU(metodo, endpoint, {
        data: [
          {
            id_patrocinador: this.id_patrocinador,
            patrocinador: this.patrocinador,
          },
        ],
      });
      if(status=='Exito'){
        this.toast.success(this.service.message,"Exito", {closeButton : true, timeOut : 3000 ,progressAnimation : 'decreasing', progressBar : true});
        this.newPatrocinador();
        this.listarPatrocinador();
        let close:HTMLElement= document.getElementById('Close') as HTMLElement;
        close.click();
      }else if (status=='Fallido'){
        this.toast.error(this.service.message,"Fallido", {closeButton : true, timeOut : 3000 ,progressAnimation : 'decreasing', progressBar : true});
      }
  }
}
