import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { Resp2 } from '../../models/Resp';

@Injectable({
  providedIn: 'root',
})
export class PmPatrocinadorService {
  //Variables
  columns: any;
  rows: any;
  message: string;
  status: string;

  //Constructor
  constructor(private api: ApiService) {}

  //Funciones
  listPatrocinador(body = {}) {
    return new Promise((res, req) => {
      this.api.Servicio('post', 'read/patrocinador', {}).subscribe(
        ({ status, data, message }: Resp2) => {
          this.columns = [
            { prop: 'id_patrocinador', name: 'ID'},
            { prop: 'patrocinador', name: 'DESCRIPCION'},
          ];
          this.rows = data;
          res('Exito');
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }

  readPatrocinador(filtro = {}) {
    return new Promise((res, req) => {
      this.api.Servicio('post', 'read/patrocinador', filtro).subscribe(
        ({ status, data, message }: Resp2) => {
          this.columns = [
            { prop: 'id_patrocinador', name: 'ID'},
            { prop: 'patrocinador', name: 'DESCRIPCION'},
          ];
          this.rows = data;
          res('Exito');
        },
        (err) => {
          console.log(err);
        }
      );
    });
  }

  CU(metodo, endpoint, json = {}) {
    return new Promise((res, req) => {
      this.api.Servicio(metodo, `${endpoint}/patrocinador`, json).subscribe(
        ({ status, data, message }: Resp2) => {
          this.message = message;
          res('Exito');
        },
        (err) => {
          console.log(err);
          res("Fallido");
        }
      );
    });
  }
}
