import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../shared/services/api.service';
import { CookieService } from 'ngx-cookie-service';
import { Tienda } from '../../shared/models/Tiendas.model';

@Component({
  selector: 'app-tienda',
  templateUrl: './tienda.component.html',
  styleUrls: ['./tienda.component.css'],
})
export class TiendaComponent implements OnInit {
  //Constructor
  constructor(private api: ApiService, private cookie: CookieService) { }

  ngOnInit(): void {
    this.listTipoTiendas();
    this.listSegmentos();
    this.listSectores();
    this.listDepartamentos();
    this.listAsesores();
    this.EMPRESA = this.tienda.EMPRESA;
    this.TIENDA = this.tienda.TIENDA;
    this.TDA_NOMBRE = this.tienda.TDA_NOMBRE;
    this.ESTADO = this.tienda.ESTADO;
    if (this.TDA_NOMBRE == '' || this.ESTADO == 'P') {
      this.Estados = [{ Texto: 'Pendiente', Valor: 'P' }];
    } else {
      this.Estados = [
        { Texto: 'Activo', Valor: 'A' },
        { Texto: 'Inactivo', Valor: 'I' },
      ];
    }
    this.DIRECCION = this.tienda.DIRECCION;
    this.NOMBRE_PROPIETARIO = this.tienda.NOMBRE_PROPIETARIO;
    this.USUARIO = this.tienda.USUARIO;
    this.PASS = this.tienda.PASS;
    this.TELEFONO = this.tienda.TELEFONO;
    this.IDTIPOTDA = this.tienda.IDTIPOTDA;
    this.CODIGO_JDE = this.tienda.CODIGO_JDE;
    if (this.tienda.CUOTA == null) {
      this.CUOTA = '0';
    } else {
      this.CUOTA = this.tienda.CUOTA;
    }
    this.IDSEGMENTO = this.tienda.IDSEGMENTO;
    this.IDSECTOR = this.tienda.IDSECTOR;
    this.LATITUD = this.tienda.LATITUD;
    this.LONGITUD = this.tienda.LONGITUD;
    this.IDESTADO2 = this.tienda.IDESTADO2;
    if (this.IDESTADO2 != '') {
      this.listMunicipios();
    }
    this.IDESTADO = this.tienda.IDESTADO;
    this.IDASESOR = this.tienda.IDASESOR;
  }

  //Variables
  @Input() tienda: Tienda;
  @Input() estado2: String;
  @Output() recarga = new EventEmitter<string>();
  TipoTiendas: any;
  Segmentos: any;
  Sectores: any;
  Departamentos: any;
  Municipios: any;
  Asesores: any;
  Estados: any;
  estado3: boolean = false;
  EMPRESA: string = '';
  TIENDA: string = '';
  TDA_NOMBRE: string = '';
  DIRECCION: string = '';
  NOMBRE_PROPIETARIO: string = '';
  USUARIO: string = '';
  PASS: string = '';
  TELEFONO: string = '';
  IDTIPOTDA: string = '';
  CODIGO_JDE: string = '';
  CUOTA: string = '';
  IDSEGMENTO: string = '';
  IDSECTOR: string = '';
  LATITUD: string = '';
  LONGITUD: string = '';
  IDESTADO: string = '';
  IDESTADO2: string = '';
  IDASESOR: string = '';
  ESTADO: string = '';

  //Funciones
  listTipoTiendas() {
    this.api.Get('Empresa/DatosBI/1/0').subscribe((data) => {
      this.TipoTiendas = data.data;
    });
  }
  listSectores() {
    this.api.Get('Empresa/DatosBI/3/0').subscribe((data) => {
      this.Sectores = data.data;
    });
  }
  listSegmentos() {
    this.api.Get('Empresa/DatosBI/2/0').subscribe((data) => {
      this.Segmentos = data.data;
    });
  }
  listDepartamentos() {
    this.api.Get('Empresa/DatosBI/4/0').subscribe((data) => {
      this.Departamentos = data.data;
    });
  }
  listMunicipios() {
    this.api.Get(`Empresa/DatosBI/5/${this.IDESTADO2}`).subscribe((data) => {
      this.Municipios = data.data;
    });
  }
  listAsesores() {
    this.api.Get('Empresa/DatosBI/6/0').subscribe((data) => {
      this.Asesores = data.data;
    });
  }
  CU() {
    this.estado3 = true;
    var endpoint: string = '';
    var accion: string = '';
    if (this.estado2 == '1') {
      endpoint = 'Empresa/Empresas';
      accion = 'C'
    } else if (this.estado2 == '2') {
      endpoint = 'Empresa/Tiendas';
      accion = 'C'
    } else if (this.estado2 == '3') {
      endpoint = 'Empresa/Tiendas';
      accion = 'U'
    }
    this.api
      .Post(endpoint, {
        ACCION: accion,
        EMPRESA_NUEVA: this.EMPRESA,
        TIENDA_NUEVA: this.TIENDA,
        nombre_empresa: this.TDA_NOMBRE,
        direccion: this.DIRECCION,
        propietario: this.NOMBRE_PROPIETARIO,
        usercajero: this.USUARIO,
        passcajero: this.PASS,
        telefono: this.TELEFONO,
        codigo_jde: this.CODIGO_JDE,
        tipo_tienda: this.IDTIPOTDA + '',
        cuota: this.CUOTA + '',
        segmento: this.IDSEGMENTO + '',
        sector: this.IDSECTOR + '',
        latitud: this.LATITUD + '',
        longitud: this.LONGITUD + '',
        estado: this.IDESTADO + '',
        asesor: this.IDASESOR + '',
        estado2: this.ESTADO + '',
      })
      .subscribe((data) => {
        this.recarga.emit(data);
      });
  }
}
