<div class="d-flex align-items-end" style="height: 100px;">
  <div class="col-5"> <input type="text" class="form-control"
      placeholder="Ingrese el Codigo de la empresa o Nombre de la tienda" id="Buscador" nombre="Buscador"
      [(ngModel)]="buscador" (keyup.enter)="listInventarios()"></div>
  <div class="col-3"></div>
</div>

<br>

<div class="col">
  <ngx-datatable class="material" [rows]="rows" rowHeight="auto" [columns]="columns" [columnMode]="ColumnMode.force"
    [limit]="10" [headerHeight]="50" [footerHeight]="50" [selectionType]="SelectionType.single" (select)="CU($event)">
  </ngx-datatable>
</div>