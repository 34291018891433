<form ngNativeValidate (submit)="updateDetalle()">
  <div class="row">
    <div class="col-2">
      <label for="Meta" class="form-label">Meta</label>
      <input type="number" class="form-control" id="Meta" name="Meta" [(ngModel)]="meta" required>
    </div>
    <div class="col-2">
      <label for="Valor" class="form-label">Valor</label>
      <input type="number" class="form-control" id="Valor" name="Valor" [(ngModel)]="valor" required>
    </div>
    <div class="col">
      <label for="Descripcion" class="form-label">Descripcion Beneficio</label>
      <input type="text" class="form-control" id="Descripcion" name="Descripcion" [(ngModel)]="descripcion" required>
    </div>
    <div class="col-1 align-self-end">
        <button type="submit" class="btn btn-dark">Guardar</button>
      </div>
  </div>
</form>
