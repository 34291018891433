export interface ListaProductos {
  COD_BARRAS: string;
  SKU: string;
  DES_SKU: string;
  MARCADES: string;
  DES_CAT_1: string;
  DES_SUBCAT1: string;
  DES_SSUBCAT: string;
}

export interface Producto {
  COD_BARRAS: string;
  SKU: string;
  DES_SKU: string;
  MARCADES: string;
  MARCA: string;
  COD_CAT_1: string;
  COD_SUB_CAT1: string;
  COD_SSUBCAT: string;
  VOLUMEN: string;
  SABOR: string;
  FABRICANTE: string;
  FABRICANTE2: string;
  EMPAQUE: string;
}

export const ProductoInit: Producto = {
  COD_BARRAS: "",
  SKU: "",
  DES_SKU: "",
  MARCADES: "",
  MARCA: "",
  COD_CAT_1: "",
  COD_SUB_CAT1: "",
  COD_SSUBCAT: "",
  VOLUMEN: "",
  SABOR: "",
  FABRICANTE: "",
  FABRICANTE2: "",
  EMPAQUE: ""
}

export type TFetchingProducto = {
  ACCION: 'C' | 'CC' | 'R' | 'U' | 'L' | 'Z';
  BARRA: string;
  SKU: string;
  DESCRIPCION: string;
  MARCA: string;
  CATEGORIA: string;
  SUBCATEGORIA: string;
  SSUBCATEGORIA: string;
  PRECIO: string;
};

export const productoInit: TFetchingProducto = {
  ACCION: 'L',
  BARRA: '',
  SKU: '',
  DESCRIPCION: '',
  MARCA: '',
  CATEGORIA: '',
  SUBCATEGORIA: '',
  SSUBCATEGORIA: '',
  PRECIO: '',
};
