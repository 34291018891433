export interface ListaEquipos {
    COMBO: string;
    EMPRESA: string;
    TIENDA: string;
    USUARIO: string;
    SERIAL: string;
};

export interface Equipo {
    EMPRESA: string;
    TIENDA: string;
    CAJA: string;
    SERIAL1: string;
    SERIAL2: string;
    SERIAL3: string;
    SERIAL4: string;
    SERIAL5: string;
    UBICACION: string;
    UBICACION2: string;
    INFORMADOR: string;
    COMENTARIO: string;
    ESTADO: boolean;
}

export const EquipoInit: Equipo = {
    EMPRESA: "",
    TIENDA: "",
    CAJA: "",
    SERIAL1: "",
    SERIAL2: "",
    SERIAL3: "",
    SERIAL4: "",
    SERIAL5: "",
    UBICACION: "",
    UBICACION2: "",
    INFORMADOR: "",
    COMENTARIO: "",
    ESTADO: false
}

export type TEquipos = {
    ACCION: 'C' | 'R' | 'U' | 'L' | 'Z';
    EMPRESA: string;
    TIENDA: string;
    CAJA: string;
    UBICACION: string;
    UBICACION2: string;
    INFORMADOR: string;
    COMENTARIO: string;
    SERIAL1: string;
    SERIAL2: string;
    SERIAL3: string;
    SERIAL4: string;
    SERIAL5: string;
};

export const equiposInit: TEquipos = {
    ACCION: 'L',
    EMPRESA: "",
    TIENDA: "",
    CAJA: "",
    UBICACION: "",
    UBICACION2: "",
    INFORMADOR: "",
    COMENTARIO: "",
    SERIAL1: "",
    SERIAL2: "",
    SERIAL3: "",
    SERIAL4: "",
    SERIAL5: ""
};
