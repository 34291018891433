<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        Producto
        <button class="btn-close" id="Close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <app-producto [producto]="producto" [estado2]="estado2" *appRecarga="recarga" (recarga)="Recarga($event)"
            (recarga2)="createMarcas()"></app-producto>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex align-items-end" style="height: 100px;">
  <div class="col-5">
    <input type="text" class="form-control" placeholder="Ingrese la Barra, SKU o Descripcion" id="Buscador"
      nombre="Buscador" [(ngModel)]="buscador" (keyup.enter)="listProductos()" />
  </div>
  <div class="col-3"></div>
  <div class="col-2">
    <button class="btn btn-dark" (click)="newProducto()">Agregar Producto</button>
  </div>
  <div class="col-2">
    <button class="btn btn-dark" (click)="newCodigoCorto()">Agregar Código Corto</button>
  </div>
  <div class="invisible">
    <button data-bs-toggle="modal" data-bs-target="#staticBackdrop" id="invisible"></button>
  </div>
</div>

<br>

<div class="col">
  <ngx-datatable class="material" [rows]="rows" rowHeight="auto" [columns]="columns" [columnMode]="ColumnMode.force"
    [limit]="10" [headerHeight]="50" [footerHeight]="50" [selectionType]="SelectionType.single"
    (select)="readProducto($event)"></ngx-datatable>
</div>