import { Component } from '@angular/core';
import { ColumnMode, SelectionType } from '@swimlane/ngx-datatable';
import { ApiService } from '../../shared/services/api.service';
import {
  Tienda,
  TiendaInit,
  ListaTiendas,
  tiendaInit,
} from '../../shared/models/Tiendas.model';
import { empresaInit } from '../../shared/models/Empresas.model';
import { Resp } from '../../shared/models/Resp';
import { ToastrService } from 'ngx-toastr';
import * as Swal from 'sweetalert2';

@Component({
  selector: 'app-tiendas',
  templateUrl: './tiendas.component.html',
  styleUrls: ['./tiendas.component.css'],
})
export class TiendasComponent {
  //Constructor
  constructor(private api: ApiService, private toast: ToastrService) {
    this.listTiendas();
  }

  //Variables
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  lista_tiendas: ListaTiendas[] = [];
  tienda: Tienda;
  rows: any;
  columns: any;
  abc: String[] = [
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
  ];
  recarga: number = 0;
  estado: boolean = false;
  buscador: string = '';
  empresa: string;
  estado2: string = '';

  //Funciones
  newEmpresa() {
    this.api
      .Post('Empresa/Empresas', {
        ...empresaInit,
        ACCION: 'Z',
      })
      .subscribe(({ data, status, message }: Resp) => {
        let empresa: string = data[0].EMPRESA.toString();
        var usuario = '';
        var pass = '';

        console.log({
          usuario: `U${empresa.padStart(4, '0')}`,
          pass: `U${empresa.slice(1, 4)}`,
          empresa: `${empresa.padStart(5, '0')}`,
        });

        if (data[0].EMPRESA <= 999) {
          usuario = 'U0' + data[0].EMPRESA;
          pass = 'U' + data[0].EMPRESA;
          data[0].EMPRESA = '00' + data[0].EMPRESA;
        } else {
          usuario = 'U' + data[0].EMPRESA;
          pass = 'U' + data[0].EMPRESA.toString().substring(1, 4);
          data[0].EMPRESA = '0' + data[0].EMPRESA;
        }
        this.tienda = {
          ...TiendaInit,
          EMPRESA: data[0].EMPRESA,
          TIENDA: '00001',
          USUARIO: usuario,
          PASS: pass,
        };
        this.recarga += 1;
        this.estado2 = '1';
      });
  }
  newTienda() {
    Swal.default
      .fire({
        icon: 'question',
        title: 'Ingresa el codigo de empresa',
        inputPlaceholder: 'Código de empresa',
        input: 'text',
        showCancelButton: true,
      })
      .then((data) => {
        if (data.value) {
          this.newTiendas2(data.value);
        }
      });
  }
  newTiendas2(empresa) {
    this.api
      .Post('Empresa/Tiendas', {
        ...tiendaInit,
        ACCION: 'Z',
        EMPRESA_NUEVA: empresa,
      })
      .subscribe(
        ({ data, status, message }: Resp) => {
          if (data[0].TIENDA != null) {
            var usuario = '';
            var pass = '';
            usuario = this.abc[data[0].TIENDA - 1] + empresa.substring(1, 5);
            pass = this.abc[data[0].TIENDA - 1] + empresa.substring(2, 5);
            if (data[0].TIENDA <= 9) {
              data[0].TIENDA = '0000' + data[0].TIENDA;
            } else {
              data[0].TIENDA = '000' + data[0].TIENDA;
            }
            this.tienda = {
              ...TiendaInit,
              EMPRESA: empresa,
              TIENDA: data[0].TIENDA,
              USUARIO: usuario,
              PASS: pass,
            };
            this.Invisible();
            this.recarga += 1;
            this.estado2 = '2';
            this.estado = false;
          } else {
            this.toast.warning('Ingresa una empresa valida', '', {
              closeButton: true,
              timeOut: 3000,
              progressAnimation: 'decreasing',
              progressBar: true,
            });
          }
        },
        (err) => {
          this.estado = true;
        }
      );
  }
  listTiendas() {
    var buscador2: string;
    if (this.buscador == '') {
      buscador2 = '0';
    } else {
      buscador2 = this.buscador;
    }
    this.api
      .Post('Empresa/Tiendas/', {
        ...tiendaInit,
        EMPRESA_NUEVA: buscador2,
        nombre_empresa: buscador2,
      })
      .subscribe(({ data, status, message }: Resp) => {
        this.lista_tiendas = [];
        data.forEach((dato: any) => {
          this.lista_tiendas.push(dato);
        });
        this.rows = this.lista_tiendas;
        this.columns = [
          { prop: 'EMPRESA', name: '#Empresa' },
          { prop: 'TIENDA', name: '#Tienda' },
          { prop: 'TDA_NOMBRE', name: 'Nombre de tienda' },
          { prop: 'NOM_ASESOR', name: 'Nombre del asesor' },
        ];
      });
  }
  readTienda(buscador2) {
    this.api
      .Post('Empresa/Tiendas/', {
        ...tiendaInit,
        ACCION: 'R',
        EMPRESA_NUEVA: buscador2.selected[0].EMPRESA,
        TIENDA_NUEVA: buscador2.selected[0].TIENDA,
      })
      .subscribe(({ data, status, message }: Resp) => {
        this.tienda = data[0];
        this.Invisible();
        this.recarga += 1;
        this.estado2 = '3';
      });
  }
  Recarga($event) {
    this.tienda = null;
    this.recarga += 1;
    this.listTiendas();
    if ($event.data[0] == null) {
      this.toast.success('Ingresado correctamente', 'Exíto', {
        closeButton: true,
        timeOut: 3000,
        progressAnimation: 'decreasing',
        progressBar: true,
      });
    } else {
      this.toast.error($event.data[0].ERROR, 'Fallido', {
        closeButton: true,
        timeOut: 3000,
        progressAnimation: 'decreasing',
        progressBar: true,
      });
    }
  }
  Invisible() {
    let invisible: HTMLElement = document.getElementById(
      'invisible'
    ) as HTMLElement;
    invisible.click();
  }
}
